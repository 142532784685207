import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import '../../Welcome.css';
import {
  Checkbox,
  Typography,
  Button,
  Select,
  Input,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  InputNumber,
  Slider,
  Radio,
  Tooltip,
  Modal,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { FaSignature } from 'react-icons/fa6';

import InputCustom from '../../../componentes/InputCustom';
import { ComponentesContext } from '../../../contextos/componentesContext';

import AuthService from '../../../services/auth.service';
import FormulariosService from '../../../services/formularios.Service';

const FirmaBuro = () => {
  const {
    UpdateFooterText,
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    CloseSession,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dateFormat = 'DD-MM-YYYY';
  const location = useLocation();

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 54) / 100,
  });

  const [valueRegimen, setvalueRegimen] = useState(1);
  const [PfTooltip, setPfTooltip] = useState('');
  const [PmTooltip, setPmTooltip] = useState('');

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);

  const [isModalOpen, setisModalOpen] = useState(false);

  const [StatusSolicitud, setStatusSolicitud] = useState(0);

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  const optionsEstados = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },
  ];

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 54) / 100,
        });
      };

      InicializaFormulario();
      UpdateFlagtimeLineForm(true);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(9);
      UpdatemovilSteps(6);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);

    var respuesta = {};
    var respuestaSolicitudes = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuesta = await FormulariosService.getInformacionMiFiel(solicitudId);
      respuestaSolicitudes = await FormulariosService.getSolicitudesActivas();
    }

    console.log('solicitudes Activas', respuestaSolicitudes);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    console.log(respuesta);

    setStatusSolicitud(respuestaSolicitudes.data[0].statusId);

    window.mifiel.widget({
      widgetId: respuesta.data.widgetId,
      appendTo: 'mifiel-widget',
      successBtnText: 'Continuar con la solicitud',
      onSuccess: {
        callToAction: async function () {
          console.log('Documento Firmado');

          let respuestaFirma =
            await FormulariosService.getFinalizaMiFiel(solicitudId);

          //window.location.reload();
          console.log(respuestaFirma);

          handleNext('/revision');
        },
      },
      onError: {
        listener: function (e) {},
      },
    });

    setTimeout(function () {
      ShowLoading(false);
    }, 3000);
  };

  const handleNext = (option) => {
    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const handleModalClose = () => {
    setisModalOpen(false);
  };

  const handleContrasena = () => {
    console.log('entro');
  };

  const handleCheck = () => {};

  return (
    <html>
      <div
        style={{
          backgroundColor: '#fff',
          paddingBottom: 50,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          height: windowSize.height,
        }}
      >
        <center>
          <Divider>
            <div className="title-steps-buro" style={{ color: '#4c4cbc' }}>
              Autorización de Consulta de Buró y SATWS
            </div>
          </Divider>
        </center>

        <div
          style={{ overflow: 'auto', maxHeight: 400, width: '100%' }}
          id="mifiel-widget"
        ></div>

        <div
          className="div-buttons-steps-buro"
          style={{ marginTop: 45, paddingLeft: 20, marginBottom: -30 }}
        >
          {
            StatusSolicitud==0 ? <Button
            className="buttons-back-forward"
            type="primary"
            onClick={() => handleNext('/datos_aval')}
            icon={<ArrowLeftOutlined />}
          >
            Atrás
            </Button>
              :
              <Button
              className="buttons-back-forward"
              type="primary"
              onClick={() => handleNext("/solicitudes_activas")}
              htmlType="submit"
            >
              Finalizar <LogoutOutlined />

            </Button>
          }
          
        </div>
      </div>

      <Modal
        title={
          <div>
            <FaSignature style={{ fontSize: 20 }} /> &nbsp;&nbsp;&nbsp;&nbsp;
            Autorización para consulta de Buró y SATWS.
          </div>
        }
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={[
          <Button type="primary" onClick={handleModalClose}>
            Enviar
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <label style={{ fontSize: 18, fontWeight: 'bold' }}>
            Tipo de credencial:
          </label>
          &nbsp;&nbsp;&nbsp;
          <label style={{ fontSize: 18 }}>CIEC</label>
        </div>

        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <label style={{ fontSize: 18, fontWeight: 'bold' }}>CIEC:</label>
          &nbsp;&nbsp;&nbsp;
          <label style={{ fontSize: 18 }}>
            <Input.Password
              placeholder=""
              style={{ width: 250 }}
              onChange={handleContrasena}
            />
          </label>
        </div>

        <div>
          <Checkbox
            onChange={handleCheck}
            style={{
              textAlign: 'justify',
              width: 400,
              marginTop: 30,
              marginLeft: 40,
              marginBottom: 20,
            }}
          >
            <label style={{ backgroundColor: 'ffff' }}>
              Autorizo a Fínamo (Administradora de Soluciones, S.A. de C.V.,
              SOFOM, E.N.R./Arrendadora Fínamo, S.A. de C.V.) para consultar mi
              comportamiento en cualquier sociedad de información crediticia,
              inclusive de manera periódica. Declaro que conozco la información
              que se solicita, el uso y alcance que se le dará, por una vigencia
              de tres años o mientras dure nuestra relación.
            </label>
          </Checkbox>
        </div>
      </Modal>
    </html>
  );
};

export default FirmaBuro;
