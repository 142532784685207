import LocalData from "../persistencia/LocalData";
import AuthService from "./auth.service";

const getEstadosSepomex = () => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}sepomex/estados`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}`
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getMunicipios = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}sepomex/municipios/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getCatalogosCodigoPostal = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}sepomex/codigo-postal/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const CatalogosSepomex = {
  getEstadosSepomex,
  getMunicipios,
  getCatalogosCodigoPostal,
};

export default CatalogosSepomex;
