import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./Welcome.css";
import { Carousel, Typography, Button, Collapse, Input } from "antd";
import { PlusOutlined, UserOutlined, LoginOutlined, CheckOutlined } from "@ant-design/icons";



import { ComponentesContext } from "../contextos/componentesContext";

import UsuariosService from '../services/usuarios.Service';
import AuthService from '../services/auth.service';

const PasswordCode = () => {
  const { ModalMessage, UpdatebuttonIngresar, UpdateuserAvatar, UpdateFlagtimeLineForm, ShowLoading, UpdateFooterText } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const location = useLocation();

  const numInputs = 6; // Cantidad de inputs deseados
  const inputRefs = useRef([]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 200,
    heightImage: window.innerHeight - 185,
  });

  const [Correo, SetCorreo] = useState(
    typeof location.state.record.correo != "undefined"
      ? location.state.record.correo != null
        ? location.state.record.correo
        : ""
      : ""
  );


  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15
  };

  const handleInputChange = (index, value) => {
    if (value.length === 1 && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyPress = (event, index) => {
    
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && event.target.value === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 200,
        heightImage: window.innerHeight - 185,
      });
    };

    UpdatebuttonIngresar("create");
    UpdateuserAvatar("");
    UpdateFlagtimeLineForm(false);
    UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");


    window.addEventListener("resize", handleResize);
    //window.addEventListener("scroll", handleScroll);
    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);

    };
  }, []);

  const handleNext = async(option) => {
    var strCodigo = "";
    
    

    for (let index = 0; index < numInputs; index++) {
      strCodigo += inputRefs.current[index].input.value;
    }

    if (strCodigo == "") {
      ModalMessage("Mensaje", "El código no puede estar en blanco.");
      return false;
    }

    if (strCodigo.length != numInputs) {
      ModalMessage("Mensaje", "Debe ingresar los " + numInputs + " dígitos.");
      return false;
    }

    ShowLoading(true);

    let respuesta = await UsuariosService.postValidarOtpCambiarPassword({
      email: Correo,
      otp: strCodigo
    });

    ShowLoading(false);

    console.log('solicitudes', respuesta);
    if (respuesta.result != true) {
      let respuestaSesion = await AuthService.getValidaSesion();
      console.log('respuesta dash validacion', respuestaSesion);
      if (respuestaSesion.result == false) {
        CloseSession();
        return false;
      }

      ModalMessage('Error', 'Se produjo un error al cargar las solicitudes.');
      return false;
    }


    if (respuesta.result) {
      ModalMessage("Mensaje", "El código se validó correctamente, ya puedes ingresar la nueva contraseña.");

      navigate(option, {
        state: { record: { correo: Correo, otp: strCodigo } },
      });

      // handleNext("/dashboard");
    }
    else {
      ModalMessage("Mensaje", respuesta.msg);
      return false;
    }

  };

  const handleReenviarCodigo = async () => {
    
    ShowLoading(true);
    let respuesta = await UsuariosService.getRestablecerPassword(Correo);
    ShowLoading(false);

    if (respuesta.result != true) {
      let respuestaSesion = await AuthService.getValidaSesion();
      console.log('respuesta dash validacion', respuestaSesion);
      if (respuestaSesion.result == false) {
        ShowLoading(false);
        CloseSession();
        return false;
      }
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar las solicitudes.');
      return false;
    }
    else {
        ModalMessage("Mensaje", "El código se envio nuevamente a su correo, favor de verificarlo.");
    }

  }


  return (

    <div style={{ backgroundColor: "#fff", marginTop: 10 }}>
      <center>
        <br></br>
        <div style={{ border: "solid #7272cb 1px", borderRadius: 15, width: "47%" }}>
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              marginTop: 5,
              borderRadius: 15,
              color: "#7272cb"

            }}
            border={0}
            className="account-validation-main-table"
          >
            <tbody>
              <tr>
                <td colSpan={1} style={{ textAlign: "center", height: 120 }}>
                  <p>
                    <Title
                      level={5}
                      style={{ textAlign: "center", marginTop: 20, color: "#4c4cbc" }}
                    >
                      Ingresa el código que te hemos enviado al correo:{" "}
                      <b>{ Correo }</b>
                    </Title>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    height: 120,
                    backgroundColor: "#f8f8fc",
                    borderRadius: 10,
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.35)",
                  }}

                >
                  <center>
                    <table style={{ marginTop: 20 }}>
                      <tbody>
                        <tr>
                          {Array.from({ length: numInputs }, (_, index) => (
                            <td key={index} style={{ width: 35 }}>
                              <Input
                                ref={el => (inputRefs.current[index] = el)}
                                maxLength={1}
                                style={{ backgroundColor: 'white', fontSize: 20 }}
                                onChange={e => handleInputChange(index, e.target.value)}
                                onKeyDown={e => {
                                  handleKeyPress(e, index);
                                  handleKeyDown(e, index);
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </center>

                  <p>Ingresa el código de 6 digitos.</p>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "center", height: 80 }}>
                 <a onClick={handleReenviarCodigo} > Reenviar código </a>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }}>
                  <Button
                    type="primary"
                    size={"default"}
                    style={{
                      width: "100%",
                      backgroundColor: "#4c4cbc",
                      height: 40,
                      boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.20)",
                      marginBottom: 60
                    }}
                    onClick={() => handleNext("/cambiar_password")}
                    icon={<CheckOutlined style={{ fontSize: 18 }} />}
                  >
                    Cambiar Contraseña
                  </Button>
                </td>
              </tr>

              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>

        <br></br>
      </center>
    </div>
  );
};

export default PasswordCode;
