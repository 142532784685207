import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ComponentesContext } from "../../../contextos/componentesContext";
import { Input, Button, Select, Form, Row, Col, Divider, Skeleton, InputNumber, Slider } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import "../../Welcome.css";
import "./ConfigurarCredito.css";

import AuthService from "../../../services/auth.service";
import FormulariosService from "../../../services/formularios.Service";


const ConfigurarCredito = () => {
  const { UpdateFooterText, UpdatebuttonIngresar, UpdateuserAvatar, loading, ShowLoading, UpdatecurrentStep, UpdateFlagtimeLineForm, UpdatemovilSteps, ModalMessage, CloseSession, UpdateFlagtimeLineFormMovil, UpdateFlagtimeLineFormProductos } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != "undefined"
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : "0"
      : "0"
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightScroll: (window.innerHeight * 60) / 100,
  });

  const [Periodos, setPeriodos] = useState({
    12: "12",
    24: "24",
    36: "36",
  });

  const [DisableForm, setDisableForm] = useState(true);

  const [montoMinimo, setmontoMinimo] = useState(0);
  const [montoMaximo, setmontoMaximo] = useState(0);
  const [destinoCreditoList, setdestinoCreditoList] = useState([]);
  const [optionsDestino, setoptionsDestino] = useState([]);

  const [PeriodoMinimo, setPeriodoMinimo] = useState(0);
  const [PeriodoMaximo, setPeriodoMaximo] = useState(0);

  const [formMontoInteres, setformMontoInteres] = useState(0);
  const [formPeriodos, setformPeriodos] = useState(0);
  const [formDestinoCredito, setformDestinoCredito] = useState(0);
  const [formMontoPago, setformMontoPago] = useState(0);

  const [intsolicitudId, setintsolicitudId] = useState(0);

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      //console.log("entro");
      AuthService.logout();
      navigate("/login", {
        state: { record: { correo: "" } },
      });


    }
    else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightScroll: (window.innerHeight * 60) / 100,
        });

      };
      InicializaFormulario();

      UpdateFlagtimeLineForm(true);
      UpdateFlagtimeLineFormMovil(true);
      UpdatebuttonIngresar("dashboard", usr.primerNombre + " " + usr.primerApellido);
      UpdateuserAvatar("dashboard");
      UpdatecurrentStep(0);
      UpdatemovilSteps(0);

      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");

      UpdateFlagtimeLineFormProductos(false);

      window.addEventListener("resize", handleResize);


      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);

      };
    }



  }, []);

  // 01   INICIALIZA EL FORMULARIO----------------------------------------------------------------------------------------
  const InicializaFormulario = async () => {

    ShowLoading(true);

    var respuesta = {};
    if (solicitudId == 0) {
      //console.log("entro 0");
      respuesta = await FormulariosService.getConfiguraCredito();
    }
    else {
      //console.log("entro diferente 0");
      respuesta = await FormulariosService.getConfiguraCreditoRegistrado(solicitudId);
    }

    //console.log("respuesta",respuesta);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();
      if(respuestaSesion.result==false){
        CloseSession();
      }
      ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      return false;
    }

    //setintsolicitudId(respuesta.data.solicitudId);

    setDisableForm(!respuesta.data.habilitado);

    //console.log(respuesta.data.habilitado);

    setformMontoInteres(respuesta.data.solicitudMonto);

    if (respuesta.data.solicitudPeriodo == "") {
      setformPeriodos("12");
    }
    else {
      setformPeriodos(respuesta.data.solicitudPeriodo);
    }

    setformDestinoCredito(respuesta.data.solicitudDestinoCreditoId == 0 ? "" : respuesta.data.solicitudDestinoCreditoId.toString());

    if (respuesta.data.solicitudDestinoCreditoId == 0) {
      var calculo = (respuesta.data.solicitudMonto * (0.29 / 12)) / (1 - Math.pow(1 + (0.29 / 12), -12));

      const formattedAmount = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
      }).format(calculo);
  
      setformMontoPago(formattedAmount);
    }
    else {
      const formattedAmount = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
      }).format(respuesta.data.solicitudMontoPago);

      setformMontoPago(formattedAmount);
    }
    
    

    setmontoMinimo(respuesta.data.montoMinimo);
    setmontoMaximo(respuesta.data.montoMaximo);

    const Arrmarks = {};
    respuesta.data.periodos.forEach((value) => {
      Arrmarks[value] = value;
    });

    const periodos = respuesta.data.periodos.map(Number); // Convertir los valores a números

    //console.log("periodos", Arrmarks);
    setPeriodos(Arrmarks);
    setPeriodoMinimo(Math.min(...periodos));
    setPeriodoMaximo(Math.max(...periodos));


    const options = respuesta.data.destinoCreditoList.map(item => ({
      value: item.id.toString(), // Convierte el ID a una cadena
      label: item.nombre,
    }));

    //console.log("opciones", options);
    setoptionsDestino(options);



    setTimeout(function () {
      form.setFieldsValue({
        configuraCredito: {
          montoInteres: respuesta.data.solicitudMonto,
          periodoCredito: respuesta.data.solicitudPeriodo
        },
      });
    }, 0);

    ShowLoading(false);
  }
  // ----------------------------------------------------------------------------------------------------------------


  // 02  GUARDA EL FORMULARIO----------------------------------------------------------------------------------------
  const onFinish = async () => {

    if (formMontoInteres == "") {
      ModalMessage("Mensaje", "El monto solicitado no puede estar en blanco.");
      return false;
    }

    const formattedAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 0,
         maximumFractionDigits: 0,
    }).format(montoMinimo);

    if (parseInt(formMontoInteres) < parseInt(montoMinimo)) {
      ModalMessage("Mensaje", "El monto solicitado debe ser mayor a " + formattedAmount);
      return false;
    }

    if (parseInt(formMontoInteres) > parseInt(montoMaximo)) {

      const formattedAmount2 = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }).format(montoMaximo);

      ModalMessage("Mensaje", "El monto solicitado no debe ser mayor a " + formattedAmount2);
      return false;
    }

    if (formDestinoCredito == 0) {
      ModalMessage("Mensaje", "Debes seleccionar el destino del crédito");
      return false;
    }

    const json = { solicitudId: solicitudId == "0" ? null : solicitudId, montoSolicitado: formMontoInteres, periodos: formPeriodos, destinoCreditoId: parseInt(formDestinoCredito), montoPagoEstimado: parseFloat(formMontoPago.toString().substring(1, formMontoPago.toString().length).replace(",", "")) };
    
    ShowLoading(true);

    if (DisableForm == false) {
      let respuestaGuardar = await FormulariosService.postGuardaConfiguraCredito(json);
      //console.log("respuesta guardar configuracion", respuestaGuardar.result);
      if (respuestaGuardar.result == true) {
        ShowLoading(false);
        navigate("/datos_cliente", {
          state: { record: { solicitudId: respuestaGuardar.data.id } },
        });
      }
      else {
        ShowLoading(false);
        ModalMessage("Error", "Se produjo un error al guardar el formulario.");
        return false;
      }
    }
    else {
      let respuesta = await FormulariosService.getSolicitudes();
      if (respuesta.result != true) {
        let respuestaSesion = await AuthService.getValidaSesion();
        //console.log("respuesta dash validacion",respuestaSesion);
        if(respuestaSesion.result==false){
          CloseSession();
          return false;
        }

        ModalMessage("Error", "Se produjo un error al cargar las solicitudes.");

        return false;
      }

      if(respuesta.data.length>0){
        navigate("/datos_cliente", {
          state: { record: { solicitudId: respuesta.data[0].id } },
        });
      }

    }

    

    ShowLoading(false);

  }
  // ----------------------------------------------------------------------------------------------------------------

  
  const handleChangeMontoInteres = async (e) => {
    setformMontoInteres(e);

    //console.log(e);

    //console.log("periodos",formPeriodos);

    if ( formPeriodos == null ) {
      setformPeriodos(12);
    }

    //console.log(formPeriodos);

    var calculo = (e * (0.29 / 12)) / (1 - Math.pow(1 + (0.29 / 12), -formPeriodos));
    const formattedAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(calculo);

    setformMontoPago(formattedAmount);
      
  }

  const handleChangePeriodos = async (e) => {
    setformPeriodos(e);
    var calculo = (formMontoInteres * (0.29 / 12)) / (1 - Math.pow(1 + (0.29 / 12), -e));
    const formattedAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(calculo);

    setformMontoPago(formattedAmount);
  }

  const handleChangeDestinoCredito = async (e) => {
    setformDestinoCredito(e);
  }

  const handleChangeMontoPago = async (e) => {
    setformMontoPago(e);
  }



  return (

    <div style={{ backgroundColor: "#fff", paddingBottom: 50, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", height: windowSize.height, minHeight: 450 }}>
      <center>
        <Divider>
          <div className="title-steps" style={{ margin: 0, color: "#4c4cbc", marginTop: 0 }}>
            Configura tu crédito
          </div>
        </Divider>

        <div className="subtitle-steps" style={{ margin: 0, marginTop: -10, color: "#404040" }}>
          Capture los siguientes datos:
        </div>
      </center>

      <center>
        <Skeleton loading={loading} active={true}>
          <Form
            className="form-steps"
            form={form}
            style={{ marginTop: -5, display: "inline-block" }}
            name="horizontal_login"
            layout="inline"
            onFinish={onFinish}
            disabled={DisableForm}
          >
            <div style={{ overflow: "auto", maxHeight: windowSize.heightScroll, width: "110%" }} >
              <div  >
                <Row style={{ marginTop: 10 }}>
                  <Col span={50} style={{ textAlign: "center" }}>
                    <Form.Item
                      name={["configuraCredito", "montoInteres"]}
                      label="¿Monto del crédito?"
                      initialvalues={12}
                      rules={[{ required: true, message: 'Ingresa el Monto de interés' }]}
                      style={{ paddingLeft: 0 }}
                    >
                      <InputNumber
                        prefix="$"
                        value={formMontoInteres}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        className="input-steps"
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={handleChangeMontoInteres}

                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <Row style={{ marginTop: 10 }}>
                <Col span={50} style={{ textAlign: "left" }}>
                  <Form.Item
                    name={["configuraCredito", "periodoCredito"]}
                    label={<div>&nbsp;&nbsp;&nbsp;&nbsp;Periodo de pago</div>}
                    initialvalues={12}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    style={{ paddingLeft: 0 }}
                  >
                    <center>
                      <Slider
                        value={formPeriodos}
                        min={PeriodoMinimo}
                        max={PeriodoMaximo}
                        marks={Periodos}
                        step={12}
                        className="slider-steps"
                        onChange={handleChangePeriodos}
                        // defaultValue={12}
                        disabled={DisableForm}
                      />
                      <div style={{fontWeight:"bold"}}>
                        {formPeriodos} Meses
                      </div>
                    </center>
                  </Form.Item>
                </Col>
              </Row>

              <div style={{ textAlign: "left", marginLeft: -20 }} className="select-steps"><label style={{color:"red", fontSize:12}}>*</label>&nbsp;Destino del crédito:</div>

              <div style={{ textAlign: "left", marginLeft: -20 }} className="select-steps">
                <Select
                  value={formDestinoCredito}
                  className="select-steps"
                  style={{ marginLeft: 0 }}
                  placeholder="Seleccione una opción"
                  options={optionsDestino}
                  onChange={handleChangeDestinoCredito}
                /></div>


              <div style={{ textAlign: "left", marginLeft: -20 }} className="select-steps">Monto del pago:</div>

              <div style={{ textAlign: "left", marginLeft: -20 }} className="select-steps">
                <Input
                  value={formMontoPago}
                  className="input-steps-configura"
                  onChange={handleChangeMontoPago}
                  disabled
                />
              </div>

            </div>
            <div style={{ width: "100%", marginTop: 15 }}>
              <Button
                className="button-forward"
                style={{ marginBottom: 10 }}
                type="primary"
                // onClick={() => handleNext()}
                htmlType="submit"
                disabled={false}
              >
                Continuar <ArrowRightOutlined />
              </Button>
            </div>
          </Form>
        </Skeleton>
      </center>

    </div>
  );
};

export default ConfigurarCredito;
