import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ComponentesContext } from '../../../contextos/componentesContext';
import '../../Welcome.css';
import './DatosClientes.css';
import {
  Typography,
  Button,
  Select,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  Radio,
  Tooltip,
  Input,
  Modal,
} from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FaCircleQuestion } from 'react-icons/fa6';

import InputCustom from '../../../componentes/InputCustom';
import RfcInput from '../../../componentes/InputRfc';
import DatePickerCustom from '../../../componentes/DatePickerCustom';
import moment from 'moment';
import dayjs from 'dayjs';

import AuthService from '../../../services/auth.service';
import FormulariosService from '../../../services/formularios.Service';
import SepomexService from '../../../services/sepomex.Service';

import imagen1 from '../../../asset/images/INEVERTICAL.png';

const DatosClientes = () => {
  const dateFormat = 'DD-MM-YYYY';
  const {
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    ModalMessage,
    RfcTypes,
    CloseSession,
    UpdateFooterText
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [UserEmail, setUserEmail] = useState('');
  const [isModalOpen, setisModalOpen] = useState(false);

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [DisableForm, setDisableForm] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 54) / 100,
  });
  const [PfTooltip, setPfTooltip] = useState('');
  const [PmTooltip, setPmTooltip] = useState('');

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);

  const [listaPaises, setlistaPaises] = useState('');

  const FormValidateMessages = {
    required: '${label} es requerido!',
    types: {
      email: '${label} no es valido!',
      number: '${label} no es un numero valido!',
    },
    number: {
      min: '${label} Debe de tener almenos ${min} caracteres!',
    },
    string: {
      min: '${label} Debe de tener almenos ${min} caracteres!',
    },
  };

  const [OptionsSexo, setOptionsSexo] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Femenino </div>,
    },
    {
      value: 2,
      label: <div className="lblOptionCombo">Masculino </div>,
    },
    {
      value: 3,
      label: <div className="lblOptionCombo">Indefinido </div>,
    },
  ]);

  const [OptionsNacionalidad, setOptionsNacionalidad] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Mexicana </div>,
    },
  ]);

  const [OptionsEstados, setOptionsEstados] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsEstadosNacimiento, setOptionsEstadosNacimiento] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsEstadosCivil, setOptionsEstadosCivil] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Soltero </div>,
    },
    {
      value: 2,
      label: <div className="lblOptionCombo">Casado </div>,
    },
    {
      value: 3,
      label: <div className="lblOptionCombo">Sin especificar </div>,
    },
  ]);

  const [valueRFC, setvalueRFC] = useState('');

  const [formPrimerNombre, setformPrimerNombre] = useState('');
  const [formSegundoNombre, setformSegundoNombre] = useState('');
  const [formPrimerApellido, setformPrimerApellido] = useState('');
  const [formSegundoApellido, setformSegundoApellido] = useState('');
  const [formSexo, setformSexo] = useState('');
  const [formFechaNacimiento, setformFechaNacimiento] = useState('');
  const [formEstadoCivil, setformEstadoCivil] = useState('');
  const [formEstadoUbicacion, setformEstadoUbicacion] = useState('');
  const [formFolioIne, setformFolioIne] = useState('');
  const [formCorreo, setformCorreo] = useState('');
  const [formCelular, setformCelular] = useState('');
  const [formCurp, setformCurp] = useState('');
  const [formRazonSocial, setformRazonSocial] = useState('');

  const [formNacionalidad, setformNacionalidad] = useState('');
  const [autocompleteLista, setautocompleteLista] = useState([]);

  const [formPaisNacimiento, setformPaisNacimiento] = useState('');
  const [formEstadoNacimiento, setformEstadoNacimiento] = useState('');

  const [formRepresentantePrimerNombre, setformRepresentantePrimerNombre] =
    useState('');
  const [formRepresentanteSegundoNombre, setformRepresentanteSegundoNombre] =
    useState('');
  const [formRepresentantePrimerApellido, setformRepresentantePrimerApellido] =
    useState('');
  const [
    formRepresentanteSegundoApellido,
    setformRepresentanteSegundoApellido,
  ] = useState('');

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    //console.log(usr);

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      setUserEmail(usr.email);
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 200,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 54) / 100,
        });
      };
      InicializaFormulario();

      UpdatecurrentStep(1);
      UpdateFlagtimeLineForm(true);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');

      UpdatemovilSteps(1);
      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  // 01   INICIALIZA EL FORMULARIO----------------------------------------------------------------------------------------
  const InicializaFormulario = async () => {
    ShowLoading(true);

    // let respuestaEstados = await SepomexService.getEstadosSepomex();

    // const Estados = respuestaEstados.data.map(item => ({
    //   value: item.id.toString(), // Convierte el ID a una cadena
    //   label: item.nombre,
    // }));

    var respuesta = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuesta = await FormulariosService.getInformacionCliente(solicitudId);
    }

    //console.log(respuesta);
    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    //BLOQUEA FORMULARIO
    setDisableForm(!respuesta.data.habilitado);
    if (!respuesta.data.habilitado==true) {
      setPmDisabled(true);
      setPfDisabled(true);
    }

    const optionestadoCivilList = respuesta.data.estadoCivilList.map(
      (item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: (
          <Tooltip placement="right" title={item.nombre}>
            <div className="lblOptionCombo">{item.nombre}</div>
          </Tooltip>
        ),
      })
    );
    setOptionsEstadosCivil(optionestadoCivilList);

    const optionestadoUbicacionList = respuesta.data.estadoUbicacionList.map(
      (item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      })
    );
    setOptionsEstados(optionestadoUbicacionList);

    const optionnacionalidadList = respuesta.data.nacionalidadList.map(
      (item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div style={{textAlign:"left"}} >{item.nombre}</div>,
        nombre: item.nombre,
      })
    );

    setlistaPaises(optionnacionalidadList);
    setOptionsNacionalidad(optionnacionalidadList);

    if (
      respuesta.data.clientePaisNacimientoId == 0 ||
      respuesta.data.clientePaisNacimientoId == null ||
      respuesta.data.clientePaisNacimientoId == 142
    ) {
      setOptionsEstadosNacimiento(optionestadoUbicacionList);
    } else {
      const filteredData = optionnacionalidadList.filter(
        (item) => item.value === respuesta.data.clientePaisNacimientoId
      );

      setOptionsEstadosNacimiento([
        {
          value: 999, // Convierte el ID a una cadena
          label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
          nombre: filteredData[0].nombre,
        },
      ]);
    }

    const ListaSexoNuevoOrden = [
      {
        id: 2,
        nombre: 'Masculino',
        descripcion: '',
        status: 'Activo',
      },
      {
        id: 1,
        nombre: 'Femenino',
        descripcion: '',
        status: 'Activo',
      },
      {
        id: 3,
        nombre: 'Indefinido',
        descripcion: '',
        status: 'Activo',
      },
    ];

    const optionsexoList = ListaSexoNuevoOrden.map((item) => ({
      value: item.id, // Convierte el ID a una cadena
      label: <div className="lblOptionCombo">{item.nombre}</div>,
    }));
    setOptionsSexo(optionsexoList);

    setvalueRFC(respuesta.data.clienteRfc);

    setformPrimerNombre(respuesta.data.clientePrimerNombre);
    setformSegundoNombre(respuesta.data.clienteSegundoNombre);
    setformPrimerApellido(respuesta.data.clientePrimerApellido);
    setformSegundoApellido(respuesta.data.clienteSegundoApellido);

    setformSexo(respuesta.data.clienteSexoId);

    var fecha = null;
    if (respuesta.data.clienteFechaNacimiento != '') {
      fecha = dayjs(
        respuesta.data.clienteFechaNacimiento.substring(8, 10) +
          '/' +
          respuesta.data.clienteFechaNacimiento.substring(5, 7) +
          '/' +
          respuesta.data.clienteFechaNacimiento.substring(0, 4),
        dateFormat
      );
      setformFechaNacimiento(fecha);
    }

    setformEstadoCivil(respuesta.data.clienteEstadoCivilId);
    setformEstadoUbicacion(respuesta.data.clienteEstadoUbicacionId.toString());
    setformFolioIne(respuesta.data.clienteFolioIdIne);

    setformCorreo(
      respuesta.data.clienteEmail == ''
        ? UserEmail
        : respuesta.data.clienteEmail
    );
    setformCelular(respuesta.data.clienteCelular);
    setformCurp(respuesta.data.clienteCurp);
    setformRazonSocial(respuesta.data.clienteRazonSocial);

    setformNacionalidad(
      respuesta.data.clienteNacionalidadId == 0
        ? 142
        : respuesta.data.clienteNacionalidadId
    );

    //PAIS NACIMIENTO
    setformPaisNacimiento(
      respuesta.data.clientePaisNacimientoId == 0
        ? 142
        : respuesta.data.clientePaisNacimientoId
    );

    setformEstadoNacimiento(
      respuesta.data.clienteEstadoNacimientoId == 0
        ? null
        : respuesta.data.clienteEstadoNacimientoId == null
        ? 999
        : respuesta.data.clienteEstadoNacimientoId.toString()
    );

    setformRepresentantePrimerNombre(respuesta.data.clientePrimerNombre);
    setformRepresentanteSegundoNombre(respuesta.data.clienteSegundoNombre);
    setformRepresentantePrimerApellido(respuesta.data.clientePrimerApellido);
    setformRepresentanteSegundoApellido(respuesta.data.clienteSegundoApellido);

    setTimeout(function () {
      form.setFieldsValue({
        datosClientes: {
          PrimerNombre: respuesta.data.clientePrimerNombre,
          SegundoNombre: respuesta.data.clienteSegundoNombre,
          ApellidoPaterno: respuesta.data.clientePrimerApellido,
          ApellidoMaterno: respuesta.data.clienteSegundoApellido,
          Nacionalidad:
            respuesta.data.clienteNacionalidadId == 0
              ? null
              : respuesta.data.clienteNacionalidadId,
          Sexo:
            respuesta.data.clienteSexoId == 0
              ? null
              : respuesta.data.clienteSexoId,
          FechaNacimiento: fecha,
          EstadoCivil:
            respuesta.data.clienteEstadoCivilId == 0
              ? null
              : respuesta.data.clienteEstadoCivilId,
          EstadoUbicacion:
            respuesta.data.clienteEstadoUbicacionId == 0
              ? null
              : respuesta.data.clienteEstadoUbicacionId.toString(),
          FolioIne: respuesta.data.clienteFolioIdIne,
          Correo:
            respuesta.data.clienteEmail == ''
              ? UserEmail
              : respuesta.data.clienteEmail,
          Celular: respuesta.data.clienteCelular,
          Curp: respuesta.data.clienteCurp,
          RazonSocial: respuesta.data.clienteRazonSocial,
          RepresentantePrimerNombre: respuesta.data.clientePrimerNombre,
          RepresentanteSegundoNombre: respuesta.data.clienteSegundoNombre,
          RepresentantePrimerApellido: respuesta.data.clientePrimerApellido,
          RepresentanteSegundoApellido: respuesta.data.clienteSegundoApellido,
          RFC: respuesta.data.clienteRfc,
          codRegFiscal:
            respuesta.data.clienteTipoRegimenFiscalId == 0
              ? 1
              : respuesta.data.clienteTipoRegimenFiscalId,
          PaisNacimiento:
            respuesta.data.clientePaisNacimientoId == 0 ||
            respuesta.data.clientePaisNacimientoId == null
              ? 142
              : respuesta.data.clientePaisNacimientoId,
          EstadoNacimiento:
            respuesta.data.clientePaisNacimientoId == null
              ? null
              : respuesta.data.clienteEstadoNacimientoId == 0
              ? null
              : respuesta.data.clienteEstadoNacimientoId == null
              ? 999
              : respuesta.data.clienteEstadoNacimientoId.toString(),
        },
      });
    }, 0);

    if (respuesta.data.clienteTipoRegimenFiscalId == 0 || respuesta.data.clienteTipoRegimenFiscalId == 1) {
      setPfaeisVisible(true);
      setPmisVisible(false);
    } else {
      setPfaeisVisible(false);
      setPmisVisible(true);
    }

    ShowLoading(false);
  };
  // ----------------------------------------------------------------------------------------------------------------

  const handleNext = async (option) => {
    var regEXRFC =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/;

    var regEXCURP =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w(\w(\w(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?$/;

    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (valueRFC.length == 0) {
      ModalMessage('Mensaje', 'El RFC no puede estar en blanco.');
      return false;
    }

    if (!regEXRFC.test(valueRFC)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El RFC no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (PfaeisVisible == true) {
      if (valueRFC.length < 13) {
        ModalMessage(
          'Mensaje',
          'El RFC no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formPrimerNombre == '') {
        ModalMessage('Mensaje', 'El Primer Nombre no puede estar en blanco.');
        return false;
      }

      // if (formSegundoNombre == "") {
      //   ModalMessage("Mensaje", "El Segundo Nombre no puede estar en blanco.");
      //   return false;
      // }

      if (formPrimerApellido == '') {
        ModalMessage('Mensaje', 'El Primer Apellido no puede estar en blanco.');
        return false;
      }

      // if (formSegundoApellido == "") {
      //   ModalMessage("Mensaje", "El Segundo Apellido no puede estar en blanco.");
      //   return false;
      // }

      if (formSexo == '') {
        ModalMessage('Mensaje', 'El Sexo no puede estar en blanco.');
        return false;
      }

      if (formFechaNacimiento == '') {
        ModalMessage(
          'Mensaje',
          'La fecha de nacimiento no puede estar en blanco'
        );
        return false;
      }

      if (formEstadoCivil == '') {
        ModalMessage('Mensaje', 'El Estado civil no puede estar en blanco.');
        return false;
      }

      if (formNacionalidad == '') {
        ModalMessage('Mensaje', 'La Nacionalidad no puede estar en blanco.');
        return false;
      }
      //console.log('nacionalidad', formEstadoUbicacion);

      if (formEstadoUbicacion == '' || formEstadoUbicacion == 0) {
        ModalMessage(
          'Mensaje',
          'El Estado no puede estar en blanco.'
        );
        return false;
      }

      if (formFolioIne == '') {
        ModalMessage('Mensaje', 'El Folio Ine no puede estar en blanco.');
        return false;
      }

      if (formFolioIne.length < 12) {
        ModalMessage('Mensaje', 'El Folio Ine no puede ser menor a 12 dígitos.');
        return false;
      }

      if (formCorreo == '') {
        ModalMessage('Mensaje', 'El Correo no puede estar en blanco.');
        return false;
      }

      if (!regEXCorreo.test(formCorreo)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El correo no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formCelular == '') {
        ModalMessage('Mensaje', 'El Celular no puede estar en blanco.');
        return false;
      }

      if (formCelular.length < 10) {
        ModalMessage('Mensaje', 'El Celular no puede ser menor a 10 dígitos.');
        return false;
      }

      if (formCurp == '') {
        ModalMessage('Mensaje', 'La CURP no puede estar en blanco.');
        return false;
      }

      if (!regEXCURP.test(formCurp)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'La CURP no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formCurp.length < 18) {
        ModalMessage(
          'Mensaje',
          'La CURP no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formPaisNacimiento == null) {
        ModalMessage(
          'Mensaje',
          'El Pais de Nacimiento no puede estar en blanco.'
        );
        return false;
      }

      if (formEstadoNacimiento == null) {
        ModalMessage(
          'Mensaje',
          'El Estado de Nacimiento no puede estar en blanco.'
        );
        return false;
      }
    } else {

      //console.log("entro");

      setformEstadoNacimiento(999);

      if (valueRFC.length < 12) {
        ModalMessage(
          'Mensaje',
          'El RFC no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formRazonSocial == '') {
        ModalMessage('Mensaje', 'La Razon Social no puede estar en blanco.');
        return false;
      }

      if (formCorreo == '') {
        ModalMessage('Mensaje', 'El Correo no puede estar en blanco.');
        return false;
      }
      
      if (!regEXCorreo.test(formCorreo)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El correo no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formEstadoUbicacion == '') {
        ModalMessage(
          'Mensaje',
          'El Estado no puede estar en blanco.'
        );
        return false;
      }

      if (formCelular == '') {
        ModalMessage('Mensaje', 'El Celular no puede estar en blanco.');
        return false;
      }

      if (formCelular.length < 10) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular de contacto no tiene un formato valido.'
        );
        return false;
      }

      if (formNacionalidad == '') {
        ModalMessage('Mensaje', 'La Nacionalidad no puede estar en blanco.');
        return false;
      }

      // if (formPaisNacimiento == null) {
      //   ModalMessage(
      //     'Mensaje',
      //     'El Pais de Nacimiento no puede estar en blanco.'
      //   );
      //   return false;
      // }

      // if (formEstadoNacimiento == null) {
      //   ModalMessage(
      //     'Mensaje',
      //     'El Estado de Nacimiento no puede estar en blanco.'
      //   );
      //   return false;
      // }

      // if (formFechaNacimiento == "") {
      //   console.log("FECHA ENTRO",formFechaNacimiento);  
      // }
      // else {
      //   console.log("FECHA NO ENTRO",formFechaNacimiento);  
      // }
      

      if (formRepresentantePrimerNombre == '') {
        ModalMessage(
          'Mensaje',
          'El Primer Nombre del Representante Legal no puede estar en blanco.'
        );
        return false;
      }

      // if (formRepresentanteSegundoNombre == "") {
      //   ModalMessage("Mensaje", "El Segundo Nombre del Representante Legal no puede estar en blanco.");
      //   return false;
      // }

      

      if (formRepresentantePrimerApellido == '') {
        ModalMessage(
          'Mensaje',
          'El Primer Apellido del Representante Legal no puede estar en blanco.'
        );
        return false;
      }

      // if (formRepresentanteSegundoApellido == "") {
      //   ModalMessage("Mensaje", "El Segundo Apellido del Representante Legal no puede estar en blanco.");
      //   return false;
      // }
    }

    
    const json = {
      solicitudId: solicitudId,
      tipoRegimenFiscalId: PfaeisVisible ? 1 : 2,
      rfc: valueRFC,
      razonSocial: formRazonSocial,
      primerNombre: PfaeisVisible
        ? formPrimerNombre
        : formRepresentantePrimerNombre,
      segundoNombre: PfaeisVisible
        ? formSegundoNombre
        : formRepresentanteSegundoNombre,
      primerApellido: PfaeisVisible
        ? formPrimerApellido
        : formRepresentantePrimerApellido,
      segundoApellido: PfaeisVisible
        ? formSegundoApellido
        : formRepresentanteSegundoApellido,
      curp: formCurp,
      estadoUbicacionId: formEstadoUbicacion,
      email: formCorreo,
      celular: formCelular,
      sexoId: formSexo,
      fechaNacimiento: formFechaNacimiento == "" ? "1900-01-01" : moment(new Date(formFechaNacimiento)).format(
        'YYYY-MM-DD'
      ),
      estadoCivilId: formEstadoCivil,
      nacionalidadId: parseInt(formNacionalidad),
      folioIdIne: formFolioIne,
      paisNacimientoId: formPaisNacimiento,
      estadoNacimientoId: formEstadoNacimiento == null ? null :
        parseInt(formEstadoNacimiento) == 999
          ? null
          : parseInt(formEstadoNacimiento),
    };

    //console.log(json);
    ShowLoading(true);

    if (DisableForm == false) {
      let respuesta =
        await FormulariosService.postGuardaInformacionCliente(json);

      if (respuesta.result != true) {
        ShowLoading(false);
        ModalMessage('Error', 'Se produjo un error al guardar el formulario.');
        return false;
      }

      //console.log("Entro Guardar");
    }
    ShowLoading(false);

    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const onFinish = () => {
    handleNext('/datos_empresa');
  };

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const filterOption = (input, option) => {
    const normalizedInput = removeAccents(input).toLowerCase();
    const normalizedOption = removeAccents(option?.nombre).toLowerCase();

    return normalizedOption.includes(normalizedInput);
  };

  const handleBack = () => {
    navigate('/configurar_credito', {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const optionsDestino = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },
  ];

  const handleInputCorreo = (e) => {
    //console.log(e);
  };

  const showConfirm = (e) => {
    setvalueRFC('');

    setTimeout(function () {
      form.setFieldsValue({
        datosClientes: {
          RFC: '',
        },
      });
    }, 0);

    if (e.target.value == 1) {
      setPfaeisVisible(true);
      setPmisVisible(false);
    } else {
      setPfaeisVisible(false);
      setPmisVisible(true);
    }
  };

  const handleInfo = () => {};

  const handleChangePaisNacimiento = async (value) => {
    //console.log(value);
    setformPaisNacimiento(value);

    let respuestaEstados = await SepomexService.getEstadosSepomex();

    const filteredData = listaPaises.filter((item) => item.value === value);

    if (value == 142) {
      //console.log(respuestaEstados);

      const optionestadoNacimientoList = respuestaEstados.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      }));
      setOptionsEstadosNacimiento(optionestadoNacimientoList);
      setformEstadoNacimiento(null);

      setTimeout(function () {
        form.setFieldsValue({
          datosClientes: {
            EstadoNacimiento: null,
          },
        });
      }, 0);
    } else {
      setOptionsEstadosNacimiento([
        {
          value: 999, // Convierte el ID a una cadena
          label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
          nombre: filteredData[0].nombre,
        },
      ]);

      setTimeout(function () {
        form.setFieldsValue({
          datosClientes: {
            EstadoNacimiento: 999,
          },
        });
      }, 0);

      setformEstadoNacimiento(999);
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        paddingBottom: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height: windowSize.height,
        minHeight: 450,
      }}
    >
      <center>
        <Divider>
          <div
            className="title-steps"
            style={{ margin: 0, color: '#4c4cbc', marginTop: 0 }}
          >
            Información del Cliente
          </div>
        </Divider>
      </center>
      <center>
        <Skeleton loading={loading} active={true}>
          <div
            style={{
              overflow: 'auto',
              width: '110%',
              maxHeight: windowSize.heightScroll,
            }}
          >
            <Form
              className="form-steps"
              form={form}
              style={{ marginTop: -5, display: 'inline-block' }}
              name="horizontal_login"
              layout="inline"
              onFinish={onFinish}
              validateMessages={FormValidateMessages}
              disabled={DisableForm}
            >
              <Form.Item name={['datosClientes', 'codRegFiscal']}>
                <Radio.Group
                  name="codRegFiscal"
                  style={{ marginLeft: 30, marginTop: 5 }}
                  onChange={(e) => {
                    showConfirm(e);
                  }}
                  value={1}
                >
                  <Tooltip placement="right" title={PmTooltip}>
                    <Radio value={2} disabled={PmDisabled}>
                      <div
                        className="radiobutton-steps"
                        style={{
                          margin: 0,
                          color: '#081b3e',
                          marginBottom: -10,
                        }}
                      >
                        PM - Persona Moral
                      </div>
                      
                    </Radio>
                  </Tooltip>

                  <Tooltip placement="left" title={PfTooltip}>
                    <Radio value={1} disabled={PfDisabled}>
                      <div
                        className="radiobutton-steps"
                        style={{
                          margin: 0,
                          color: '#081b3e',
                          marginBottom: -10,
                        }}
                      >
                        PFAE -Persona Física con Actividad Empresarial
                      </div>
                    </Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>

              <div
                style={{
                  display: PmisVisible ? 'block' : 'none',
                }}
              >
                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'RFC']}
                      label="RFC (Con Homoclave)"
                      style={{ paddingLeft: 0 }}
                      rules={[{ required: true, message: 'Ingresa tu RFC' }]}
                    >
                      <center>
                        <RfcInput
                          className="input-steps"
                          value={valueRFC}
                          onChange={setvalueRFC}
                          tipo={RfcTypes.Moral}
                          onInput={(e) =>
                            (e.target.value = e.target.value.toUpperCase())
                          }
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'RazonSocial']}
                      label="Razón Social"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        { required: true, message: 'Ingresa tu Razón Social' },
                      ]}
                    >
                      <center>
                        <InputCustom
                          className="input-steps"
                          value={formRazonSocial}
                          onChange={setformRazonSocial}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'correo']}
                      label="Correo"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa tu correo electrónico',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido',
                        },
                      ]}
                    >
                      <center>
                        <Input
                          className="input-steps"
                          value={formCorreo}
                          onChange={(e) => setformCorreo(e.target.value)}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'EstadoUbicacion']}
                      label="Estado Ubicación"
                      rules={[
                        {
                          required: true,
                          message: 'Selecciona el Estado de tu ubicación',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsEstados}
                        onChange={setformEstadoUbicacion}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'Celular']}
                      label="Número Celular"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa tu Número de Celular',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          value={formCelular}
                          onChange={setformCelular}
                          inputtype={InputTypes.Integer}
                          key={1}
                          maxLength={10}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                  <Form.Item
                      name={['datosClientes', 'Nacionalidad']}
                      label={<label style={{textAlign:"left"}} className='input-steps'>&nbsp;Nacionalidad:</label>}
                      rules={[
                        {
                          required: true,
                          message: 'Seleciona tu Nacionalidad',
                        },
                      ]}
                      colon={false}
                      className="input-steps"
                    >
                      <center>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Seleccione una opción"
                          options={OptionsNacionalidad}
                          onChange={setformNacionalidad}
                          value={formNacionalidad}
                          filterOption={filterOption}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>


                <Typography.Title
                  level={4}
                  style={{ marginTop: 5, marginLeft: -20 }}
                >
                  Representante Legal
                </Typography.Title>
                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'RepresentantePrimerNombre']}
                      colon={false}
                      label={<label style={{textAlign:"left"}} className='select-steps-ocupacion'><label style={{color:"red", fontSize:12}}>*</label>&nbsp;Primer Nombre:</label>}
                    >
                      <InputCustom
                        value={formRepresentantePrimerNombre}
                        onChange={setformRepresentantePrimerNombre}
                        inputtype={InputTypes.Text}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'RepresentanteSegundoNombre']}
                      label="Segundo Nombre"
                    >
                      <center>
                        <InputCustom
                          value={formRepresentanteSegundoNombre}
                          onChange={setformRepresentanteSegundoNombre}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'RepresentantePrimerApellido']}
                      colon={false}
                      label={<label style={{textAlign:"left"}} className='select-steps-ocupacion'><label style={{color:"red", fontSize:12}}>*</label>&nbsp;Apellido Paterno</label>}
                    >
                      <InputCustom
                        value={formRepresentantePrimerApellido}
                        onChange={setformRepresentantePrimerApellido}
                        inputtype={InputTypes.Text}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'RepresentanteSegundoApellido']}
                      label="Apellido Materno"
                    >
                      <center>
                        <InputCustom
                          value={formRepresentanteSegundoApellido}
                          onChange={setformRepresentanteSegundoApellido}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: PfaeisVisible ? 'block' : 'none',
                }}
              >
                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'RFC']}
                      label="RFC (Con Homoclave)"
                      style={{ paddingLeft: 0 }}
                      rules={[{ required: true, message: 'Ingresa tu RFC' }]}
                    >
                      <center>
                        <RfcInput
                          className="input-steps"
                          value={valueRFC}
                          onChange={setvalueRFC}
                          tipo={RfcTypes.Fisica}
                          onInput={(e) =>
                            (e.target.value = e.target.value.toUpperCase())
                          }
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'PrimerNombre']}
                      label="Primer Nombre"
                      rules={[
                        { required: true, message: 'Ingresa tu Primer Nombre' },
                      ]}
                    >
                      <InputCustom
                        value={formPrimerNombre}
                        onChange={setformPrimerNombre}
                        inputtype={InputTypes.Text}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'SegundoNombre']}
                      label="Segundo Nombre"
                    >
                      <center>
                        <InputCustom
                          value={formSegundoNombre}
                          onChange={setformSegundoNombre}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'ApellidoPaterno']}
                      label="Apellido Paterno"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa tu Apellido Paterno',
                        },
                      ]}
                    >
                      <InputCustom
                        value={formPrimerApellido}
                        onChange={setformPrimerApellido}
                        inputtype={InputTypes.Text}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'ApellidoMaterno']}
                      label="Apellido Materno"
                    >
                      <center>
                        <InputCustom
                          value={formSegundoApellido}
                          onChange={setformSegundoApellido}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'Sexo']}
                      label="Sexo del cliente"
                      rules={[{ required: true, message: 'Ingresa tu Sexo' }]}
                    >
                      <Select
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsSexo}
                        onChange={setformSexo}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'FechaNacimiento']}
                      label="Fecha de Nacimiento"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa tu Fecha de Nacimiento',
                        },
                      ]}
                    >
                      <center>
                        <DatePickerCustom
                          className="datepicker-clientes"
                          format={dateFormat}
                          // ref={(dateSelect) => { this.dateSelect = dateSelect }}
                          // disabledDate={(current) => {
                          //   return moment().add(-1, 'days')  >= current;
                          //   }}
                          value={formFechaNacimiento}
                          onChange={setformFechaNacimiento}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'EstadoCivil']}
                      label="Estado Civil"
                      rules={[
                        {
                          required: true,
                          message: 'Selecciona tu Estado Civil',
                        },
                      ]}
                    >
                      <Select
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsEstadosCivil}
                        onChange={setformEstadoCivil}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'Nacionalidad']}
                      label="Nacionalidad"
                      rules={[
                        {
                          required: true,
                          message: 'Seleciona tu Nacionalidad',
                        },
                      ]}
                    >
                      <center>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="select-steps-clientes"
                          placeholder="Seleccione una opción"
                          options={OptionsNacionalidad}
                          onChange={setformNacionalidad}
                          value={formNacionalidad}
                          filterOption={filterOption}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'EstadoUbicacion']}
                      label="Estado"
                      rules={[
                        {
                          required: true,
                          message: 'Selecciona tu Estado de Recidencia',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsEstados}
                        onChange={setformEstadoUbicacion}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'FolioIne']}
                      label={
                        <>
                          <Tooltip
                            placement="up"
                            title={
                              <img className="img-ine" src={imagen1}></img>
                            }
                            overlayInnerStyle={{ width: 365, marginTop: -100 }}
                          >
                            <a
                              style={{ color: '#4c4cbc', fontSize: 15 }}
                              onClick={handleInfo}
                            >
                              <FaCircleQuestion />
                            </a>
                          </Tooltip>{' '}
                          &nbsp; Folio ID INE
                        </>
                      }
                      rules={[
                        { required: true, message: 'Ingresa tu Folio ID INE' },
                      ]}
                    >
                      <center>
                        <InputCustom
                          value={formFolioIne}
                          onChange={setformFolioIne}
                          inputtype={InputTypes.Integer}
                          maxLength={13}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'Correo']}
                      label="Correo"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa tu correo electrónico',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido',
                        },
                      ]}
                    >
                      <Input
                        value={formCorreo}
                        onChange={(e) => setformCorreo(e.target.value)}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'Celular']}
                      label="Número Celular"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa tu Numero de Celular',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          value={formCelular}
                          onChange={setformCelular}
                          inputtype={InputTypes.Integer}
                          key={1}
                          maxLength={10}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'Curp']}
                      label="CURP"
                      style={{ paddingLeft: 0 }}
                      rules={[{ required: true, message: 'Ingresa tu Curp' }]}
                    >
                      <center>
                        <InputCustom
                          value={formCurp}
                          onChange={setformCurp}
                          className="input-steps"
                          inputtype={InputTypes.Curp}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'PaisNacimiento']}
                      label="País de Nacimiento"
                      rules={[
                        {
                          required: true,
                          message: 'Seleciona tu Nacionalidad',
                        },
                      ]}
                    >
                      <center>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="select-steps-clientes"
                          placeholder="Seleccione una opción"
                          options={OptionsNacionalidad}
                          onChange={(e) => handleChangePaisNacimiento(e)}
                          value={formPaisNacimiento}
                          filterOption={filterOption}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['datosClientes', 'EstadoNacimiento']}
                      label="Estado de Nacimiento"
                      rules={[
                        {
                          required: true,
                          message: 'Selecciona tu Estado de Nacimiento',
                        },
                      ]}
                    >
                      <Select
                         showSearch
                         optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsEstadosNacimiento}
                        onChange={setformEstadoNacimiento}
                        value={formEstadoNacimiento}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>

          <div
            className="div-buttons"
            style={{ marginTop: 20, paddingLeft: 3, marginBottom: -40 }}
          >
            <Row gutter={5} style={{ marginLeft: 0 }}>
              <Col span={12}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleBack()}
                  icon={<ArrowLeftOutlined />}
                >
                  Atrás
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleNext('/datos_empresa')}
                  htmlType="submit"
                >
                  Continuar <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </center>
    </div>
  );
};

export default DatosClientes;
