import React from "react";
import Main from "./pages/Main"
import Welcome from "./pages/Welcome";
import Create from "./pages/Create";
import Login from "./pages/Login";
import MailCode from "./pages/MailCode";
import DashBoard from "./pages/Logged/Dashboard";

import SolicitudesActivas from "./pages/Logged/SolicitudesActivas";

import SolicitudContacto from "./pages/SolicitudContacto";
import ConfigurarCredito from "./pages/Logged/Forms/ConfigurarCredito";
import DatosClientes from "./pages/Logged/Forms/DatosClientes";
import DatosEmpresas from "./pages/Logged/Forms/DatosEmpresas";
import SolicitudCambioContrasena from "./pages/SolicitudCambioContra";
import PasswordCode from "./pages/PasswordCode";
import CambioContrasena from "./pages/CambioContrasena";
import DomicilioLocalizacion from "./pages/Logged/Forms/DomicilioLocalizacion";
import DatosContacto from "./pages/Logged/Forms/DatosContacto";
import DatosAval from "./pages/Logged/Forms/DatosAval";

import DocumentosCliente from "./pages/Logged/Forms/DocumentosCliente";
import EntrevistaPld from "./pages/Logged/Forms/EntrevistaPld";
import ConsultaSATWS from "./pages/ConsultaSATWS";

import FirmaBuro from "./pages/Logged/Forms/FirmaBuro";
import Revision from "./pages/Logged/Forms/Revision";

import AvisoPrivacidad from "./pages/Privacidad";

import TerminosCondiciones from "./pages/Condiciones"

import CambioContrasenaSinLogin from "./pages/CambioContrasenaSinLogin";



import './Fonts.css';


import {
  Routes,
  Route,Redirect
} from 'react-router-dom';


const App = () => {

  console.error = (error) => {};
  console.warn = (warning) => {};

  function PrivateRoute({ component: Component, ...rest }) {
    // useAuth es un hook personalizado para obtener el estado de autenticación del usuario actual
   
  
    return (
      <Route
        {...rest}
        render={(props) =>
         <Component {...props}  />
        }
      />
    );
  }
 
  return(
  
    <div className="App" style={{fontFamily: 'Red Hat Display, sans-serif'}}>

      <Routes>
  
          <Route path='/' element={< Main/>}>
            <Route index path='/' element={< Welcome/>}></Route>
            
            <Route path='create/:parametro' element={< Create />}></Route>
            <Route path='create' element={< Create />}></Route>
          
            <Route   path='codevalidation' element={< MailCode/>}></Route>
            <Route   path='login' element={< Login/>}></Route>
            <Route   path='dashboard' element={< DashBoard/>}></Route>
            <Route   path='solicitud_credito' element={< SolicitudContacto/>}></Route>
            <Route   path='configurar_credito' element={< ConfigurarCredito/>}></Route>
            <Route   path='datos_cliente' element={< DatosClientes/>}></Route>
            <Route   path='datos_empresa' element={< DatosEmpresas/>}></Route>
            <Route   path='solicitud_cambio_contra' element={< SolicitudCambioContrasena/>}></Route>
            <Route   path='codevalidation_password' element={< PasswordCode/>}></Route>
            <Route   path='cambio_password' element={< CambioContrasena/>}></Route>
            <Route   path='domicilio_localizacion' element={< DomicilioLocalizacion/>}></Route>
            <Route   path='datos_contacto' element={< DatosContacto/>}></Route>
            <Route   path='datos_aval' element={< DatosAval/>}></Route>

            <Route   path='documentos' element={< DocumentosCliente/>}></Route>
            <Route   path='entrevistapld' element={< EntrevistaPld/>}></Route>
            <Route   path='consulta_satws' element={< ConsultaSATWS/>}></Route>
            <Route   path='firma_buro' element={< FirmaBuro/>}></Route>
            <Route path='revision' element={< Revision />}></Route>
          
            <Route path='solicitudes_activas' element={< SolicitudesActivas />}></Route>
          
            <Route path='cambiar_password' element={< CambioContrasenaSinLogin />}></Route>
          
            <Route path='aviso_privacidad' element={< AvisoPrivacidad />}></Route>
            <Route path='terminos_condiciones' element={< TerminosCondiciones />}></Route>

            
          
            
            
            
            {/* <Route   path='create' element={< CreateAccount/>}></Route>
            <Route   path='validation' element={< AccountValidation/>}></Route>
            <Route   path='set-password' element={< AccountPassword/>}></Route>
            <Route   path='login' element={< AccountLogin/>}></Route> */}
            
            
          </Route>

          {/* <Route exact path='/dashboard' element={< DashBoard/>}>
            <Route index  path='form1' element={< Step1/>}></Route>
            <Route index  path='form2' element={< Step2/>}></Route>
            <Route index  path='form3' element={< Step3/>}></Route>
            <Route index  path='form4' element={< Step4/>}></Route>
            

          </Route> */}

          
          

          
    </Routes>

    </div>
  
    

)};
export default App;