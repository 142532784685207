import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InputCustom from "../../../componentes/InputCustom";
import SearchCustom from "../../../componentes/SearchCustom";
import { ComponentesContext } from "../../../contextos/componentesContext";
import "../../Welcome.css";
import "./DomicilioLocalizacion.css";
import { Typography, Button, Select, Form, Row, Col, Divider, Skeleton, Radio, Tooltip } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import AuthService from "../../../services/auth.service";
import SepomexService from "../../../services/sepomex.Service";
import FormulariosService from "../../../services/formularios.Service";

const DomicilioLocalizacion = () => {
  const { UpdateFooterText,UpdatebuttonIngresar, UpdateuserAvatar, loading, UpdatecurrentStep, InputTypes, UpdateFlagtimeLineForm, UpdatemovilSteps, CloseSession, ModalMessage, ShowLoading } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [DisableForm, setDisableForm] = useState(false);

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != "undefined"
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : "0"
      : "0"
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 60) / 100,
  });

  const [PfTooltip, setPfTooltip] = useState("");
  const [PmTooltip, setPmTooltip] = useState("");

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);


  const [OptionsEstados, setOptionsEstados] = useState([
    {
      value: 'Sinaloa',
      label: (<div className="lblOptionCombo" >Sinaloa </div>),
    },
    {
      value: 'Sonora',
      label: (<div className="lblOptionCombo" >Sonora </div>),
    },

  ]);

  const [OptionsMunicipio, setOptionsMunicipio] = useState([
    {
      value: 'culiacan',
      label: (<div className="lblOptionCombo" >Culiacan </div>),
    },
    {
      value: 'navolato',
      label: (<div className="lblOptionCombo" >Navolato </div>),
    },

  ]);

  const [OptionsColonia, setOptionsColonia] = useState([
    {
      value: 'centro',
      label: (<div className="lblOptionCombo" >Centro </div>),
    },
    {
      value: 'miguel aleman',
      label: (<div className="lblOptionCombo" >Miguel Aleman </div>),
    },

  ]);

  const [formCp, setformCp] = useState("");
  const [formEstado, setformEstado] = useState("");
  const [formMunicipio, setformMunicipio] = useState("");
  const [formColonia, setformColonia] = useState("");
  const [formCalle, setformCalle] = useState("");
  const [formExt, setformExt] = useState("");
  const [formInt, setformInt] = useState("");

  const [disableSelectColonia, setenableSelectColonia] = useState(true);

  useEffect(() => {

    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      console.log("entro");
      AuthService.logout();

      navigate("/login", {
        state: { record: { correo: "" } },
      });


    }
    else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 60) / 100,
        });
      };

      InicializaFormulario();

      UpdateFlagtimeLineForm(true);
      UpdatebuttonIngresar("dashboard", usr.primerNombre + " " + usr.primerApellido);
      UpdateuserAvatar("dashboard");
      UpdatecurrentStep(3);
      UpdatemovilSteps(3);
      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener("resize", handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);

      };
    }


  }, []);



  const InicializaFormulario = async () => {

    ShowLoading(true);

    var respuesta = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      return false;
    }
    else {
      respuesta = await FormulariosService.getInformacionDomicilioLocalizacion(solicitudId);
    }

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      }
      else {
        ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    setDisableForm(!respuesta.data.habilitado);

    console.log(respuesta);


    setformCp(respuesta.data.solicitudCodigoPostal);

    const optionEstadosSepomex= [{value: parseInt(respuesta.data.solicitudEstado.id), label: respuesta.data.solicitudEstado.nombre  }];
    setOptionsEstados(optionEstadosSepomex);
    setformEstado(parseInt(respuesta.data.solicitudEstado.id));

    const optionMunicipiosSepomex= [{value: parseInt(respuesta.data.solicitudMunicipio.id), label: respuesta.data.solicitudMunicipio.nombre  }];
    setOptionsMunicipio(optionMunicipiosSepomex);
    setformMunicipio(parseInt(respuesta.data.solicitudMunicipio.id));
    
    const optionColoniasSepomex = respuesta.data.coloniaList.map(item => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: item.nombre,
    }));
    setOptionsColonia(optionColoniasSepomex);
    setformColonia(respuesta.data.solicitudColoniaId);
    setenableSelectColonia(false);

    setformCalle(respuesta.data.solicitudCalle);
    setformExt(respuesta.data.solicitudNumeroExterior);
    setformInt(respuesta.data.solicitudNumeroInterior);

    
    setTimeout(function () {
      form.setFieldsValue({
        domicilioLocalizacion: {
          calle: respuesta.data.solicitudCalle
        },
      });
    }, 0);

    if (!respuesta.data.habilitado==true) {
      setenableSelectColonia(true);
      setPmDisabled(true);
      setPfDisabled(true);
    }

    ShowLoading(false);
  }

  const handleNext = async (option) => {
    
    if (formCp == "") {
      ModalMessage("Mensaje", "El Codigo Postal no puede estar en blanco.");
      return false;
    }

    if (formColonia == null) {
      ModalMessage("Mensaje", "La colonia no puede estar en blanco.");
      return false;
    }

     if (formCalle == "") {
       ModalMessage("Mensaje", "La calle no puede estar en blanco.");
       return false;
     }

     if (formExt == "") {
       ModalMessage("Mensaje", "El Número Exterior no puede estar en blanco.");
       return false;
     }

    const json = {
      "solicitudId": solicitudId,
      "codigoPostal": formCp,
      "coloniaId": formColonia,
      "calle": formCalle,
      "numeroExterior": formExt,
      "numeroInterior": formInt
    };


    

    ShowLoading(true);

    if (DisableForm == false) {
      let respuesta = await FormulariosService.postGuardaDomicilioLocalizacion(json);
      if (respuesta.result != true) {
        ShowLoading(false);
        ModalMessage("Error", "Se produjo un error al guardar el formulario.");
        return false;
      }
  
      ShowLoading(false);
      
    }

    ShowLoading(false);
    
    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });

  };

  const onFinish = () => {
    handleNext("/datos_empresa");
  }

  const onChange = () => {

  }

  const handleBack = () => {

    navigate("/datos_empresa", {
      state: { record: { solicitudId: solicitudId } },
    });

    //handleNext("/datos_empresa");
  }


  const handeChangeCargarDomicilioFiscal = async (e) => {
    if (e.target.value == 1) {
      ShowLoading(true);

      var respuesta = {};
      if (solicitudId == 0) {
        ShowLoading(false);
        ModalMessage("Error", "Se produjo un error al cargar el domicilio fiscal.");
        return false;
      }
      else {
        respuesta = await FormulariosService.getInformacionEmpresa(solicitudId);
      }

      if (respuesta.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        }
        else {
          ModalMessage("Error", "Se produjo un error al el domicilio fiscal.");
        }
        return false;
      }

      if (respuesta.data.length == 0) {
        ShowLoading(false);
        return false;
      }

      setformCp(respuesta.data.empresaCodigoPostal);

      const optionEstadosSepomex = [{ value: parseInt(respuesta.data.empresaEstado.id), label: respuesta.data.empresaEstado.nombre }];
      setOptionsEstados(optionEstadosSepomex);
      setformEstado(parseInt(respuesta.data.empresaEstado.id));

      const optionMunicipiosSepomex = [{ value: parseInt(respuesta.data.empresaMunicipio.id), label: respuesta.data.empresaMunicipio.nombre }];
      setOptionsMunicipio(optionMunicipiosSepomex);
      setformMunicipio(parseInt(respuesta.data.empresaMunicipio.id));

      const optionColoniasSepomex = respuesta.data.coloniaList.map(item => ({
        value: parseInt(item.id), // Convierte el ID a una cadena
        label: item.nombre,
      }));
      setOptionsColonia(optionColoniasSepomex);
      setformColonia(respuesta.data.empresaColoniaId);
      setenableSelectColonia(false);

      setformCalle(respuesta.data.empresaCalle);
      setformExt(respuesta.data.empresaNumeroExterior);
      setformInt(respuesta.data.empresaNumeroInterior);


      setTimeout(function () {
        form.setFieldsValue({
          domicilioLocalizacion: {
            calle: respuesta.data.empresaCalle
          },
        });
      }, 0);

      ShowLoading(false);
    }
    else {
      setformCp("");
      setformEstado("");
      setformMunicipio("");
      setenableSelectColonia(true);
      setformColonia("");
      setformCalle("");
      setformExt("");
      setformInt("");

      setTimeout(function () {
        form.setFieldsValue({
          domicilioLocalizacion: {
            calle: ""
          },
        });
      }, 0);
    }

  };

  const getCpData = async (strCp) => {
    ShowLoading(true);

    if (strCp == "") {
      ModalMessage("Error", "El código postal no puede estar en blanco.");
      ShowLoading(false);
      return false;
    }
    
    let cpData = await SepomexService.getCatalogosCodigoPostal(strCp);

    if (cpData.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      }
      else {
        ModalMessage("Error", "Se produjo un error al cargar la colonia.");
      }
      return false;
    }

    const optionEstadosSepomex = [{ value: parseInt(cpData.data.estado.id), label: cpData.data.estado.nombre }];
    setOptionsEstados(optionEstadosSepomex);
    setformEstado(parseInt(cpData.data.estado.id));

    const optionMunicipiosSepomex = [{ value: parseInt(cpData.data.municipio.id), label: cpData.data.municipio.nombre }];
    setOptionsMunicipio(optionMunicipiosSepomex);
    setformMunicipio(parseInt(cpData.data.municipio.id))

    const optionColoniasSepomex = cpData.data.coloniaList.map(item => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: item.nombre,
    }));

    setOptionsColonia(optionColoniasSepomex);
    setenableSelectColonia(false);
    ShowLoading(false);
  };

  const onSearch = (value, event) => {
    event.preventDefault();
    getCpData(value);
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filterOption = (input, option) => {
    const normalizedInput = removeAccents(input).toLowerCase();
    const normalizedOption = removeAccents(option?.label).toLowerCase();
    
    return normalizedOption.includes(normalizedInput);
  };


  return (

    <div style={{ backgroundColor: "#fff", paddingBottom: 50, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", height: windowSize.height, minHeight: 450 }}>
      <center>
        <Divider>
          <div className="title-steps" style={{ margin: 0, color: "#4c4cbc", marginTop: 0 }}>
            Domicilio de localización
          </div>
        </Divider>

      </center>
      <center>
        <Skeleton loading={loading} active={true}>

          <div style={{ overflow: "auto", width: "112%", maxHeight: windowSize.heightScroll }}>
            <Form
              className="form-steps"
              form={form}
              style={{ marginTop: -5, marginLeft: -25, display: "inline-block" }}
              name="horizontal_login"
              layout="inline"
              onFinish={onFinish}
              disabled={DisableForm}
            >

              <center>
                <div className="subtitle-localizacion" style={{ color: "#4c4cbc", marginTop: 0 }}>
                  ¿Es el domicilio de localización igual al domicilio fiscal?
                </div>
              </center>

              <Form.Item style={{ marginBottom: 30 }} name={["inversionista", "codRegFiscal"]}>
                <Radio.Group
                  name="codRegFiscal"
                  onChange={(e) => {
                    handeChangeCargarDomicilioFiscal(e);
                  }}
                  value={2}
                >
                  <Tooltip placement="left" title={PfTooltip}>
                    <Radio value={2} disabled={PfDisabled}>
                      <Typography.Title
                        level={5}
                        style={{
                          margin: 0,
                          color: "#081b3e",
                          marginBottom: -10,
                        }}
                      >
                        No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography.Title>
                    </Radio>
                  </Tooltip>

                  <Tooltip placement="right" title={PmTooltip}>
                    <Radio value={1} disabled={PmDisabled}>
                      <Typography.Title
                        level={5}
                        style={{
                          margin: 0,
                          color: "#081b3e",
                          marginBottom: -10,
                        }}
                      >
                        Si
                      </Typography.Title>
                    </Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>

              <Row gutter={20}>
                <Col span={11} style={{ textAlign: "left" }}>
                  <div className="input-steps-cols"><label style={{color:"red", fontSize:12}}>*</label> CP:</div>
                </Col>
                <Col span={11} style={{ textAlign: "left" }}>
                  <div className="input-steps-cols">Estado:</div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={11} style={{ textAlign: "left" }}>
                  <center>
                    <SearchCustom className="search-steps-cols" inputtype={InputTypes.Integer} value={formCp} maxLength={5} placeholder="Ingrese un C.P." onSearch={onSearch} onChange={setformCp} />
                  </center>
                </Col>
                <Col span={11} style={{ textAlign: "left" }}>
                  <center>

                    <Select
                      className="input-steps-cols"
                      placeholder="Seleccione una opción"
                      options={OptionsEstados}
                      onChange={setformEstado}
                      value={formEstado}
                      disabled
                    />
                  </center>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={11} style={{ textAlign: "left" }}>
                  <div className="input-steps-cols">Municipio/Delegación:</div>
                </Col>
                <Col span={11} style={{ textAlign: "left" }}>
                  <div className="input-steps-cols"><label style={{color:"red", fontSize:12}}>*</label> Colonia:</div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={11} style={{ textAlign: "left" }}>
                  <center>
                    <Select
                      className="input-steps-cols"
                      placeholder="Seleccione una opción"
                      options={OptionsMunicipio}
                      onChange={setformMunicipio}
                      value={formMunicipio}
                      disabled
                    />
                  </center>
                </Col>
                <Col span={11} style={{ textAlign: "left" }}>
                  <center>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      className="input-steps-cols"
                      placeholder="Seleccione una opción"
                      options={OptionsColonia}
                      onChange={setformColonia}
                      value={formColonia}
                      disabled={disableSelectColonia}
                      filterOption={filterOption}
                    />
                  </center>
                </Col>
              </Row>
              <Row className="row-3-cols" >
                <Col span={9} >
                  <Form.Item
                    name={["domicilioLocalizacion", "calle"]}
                    label="Calle"
                    rules={[
                      { required: true, message: "Ingresa la calle del domicilio de localización" },
                    ]}
                  >
                    <InputCustom inputtype={InputTypes.Text} key={1} value={formCalle} onChange={setformCalle} />
                  </Form.Item>
                </Col>
                <Col span={7} style={{ textAlign: "left" }}>
                  <Form.Item
                    name={["domicilioLocalizacion", "ext"]}
                    label="No. Ext."
                    rules={[
                      { required: true, message: "Ingresa el Número Exterior del domicilio de localización" },
                    ]}
                  >
                    <center>
                      <InputCustom inputtype={InputTypes.Integer} key={1} value={formExt} onChange={setformExt} />
                    </center>
                  </Form.Item>
                </Col>

                <Col span={7} style={{ textAlign: "left" }}>
                  <Form.Item
                    name={["domicilioLocalizacion", "int"]}
                    label="No. Int."

                  >
                    <center>
                      <InputCustom inputtype={InputTypes.Integer} key={1} value={formInt} onChange={setformInt} />
                    </center>
                  </Form.Item>
                </Col>
              </Row>


            </Form>
          </div>


          <div style={{ width: "100%", marginTop: 45, paddingLeft: 10, marginBottom: -30 }}>

            <Row style={{ marginLeft: 0 }}>
              <Col span={12}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleBack()}
                  icon={<ArrowLeftOutlined />}
                >
                  Atrás
                </Button>
              </Col>
              <Col>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleNext("/datos_contacto")}
                  htmlType="submit"
                >
                  Continuar <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </center>
    </div>
  );
};

export default DomicilioLocalizacion;
