import {DatePicker} from 'antd';
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';


export default function DatePickerCustom({...props }) {



    return(
        <DatePicker locale={locale}   {...props}/>
    )
}