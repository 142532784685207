import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate,useLocation } from "react-router-dom";

import "../../Welcome.css";
import "./DocumentosCliente.css"

import { Checkbox, Typography, Button, Select, Input, Form, Row, Col, Divider, Skeleton, InputNumber, Slider, Radio, Tooltip, Avatar } from "antd";
import { PlusOutlined, UserOutlined, ArrowLeftOutlined, ArrowRightOutlined, IdcardOutlined } from "@ant-design/icons";
import { FaAddressCard, FaFileCircleCheck, FaFileInvoiceDollar } from "react-icons/fa6";

import { ComponentesContext } from "../../../contextos/componentesContext";
import AuthService from "../../../services/auth.service";
import FormulariosService from "../../../services/formularios.Service";

import UploadButtonDocuments from "../../../componentes/UploadButtonDocuments";

const DocumentosCliente = () => {
  const { UpdateFooterText,ModalMessage, UpdatebuttonIngresar, UpdateuserAvatar, loading, UpdatecurrentStep, InputTypes, UpdateFlagtimeLineForm, UpdatemovilSteps, ShowLoading, CloseSession, UpdateintSolicitudId } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const location = useLocation();

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != "undefined"
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : "0"
      : "0"
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 50) / 100,
  });

  const [PmisVisible, setPmisVisible] = useState(true);
  const [PmisVisibleAval, setPmisVisibleAval] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);

  const [identificacionClienteStatus, setidentificacionClienteStatus] = useState(0);
  const [comprobanteClienteStatus, setcomprobanteClienteStatus] = useState(0);
  const [caratulaClienteStatus, setcaratulaClienteStatus] = useState(0);
  const [corporativaClienteStatus, setcorporativaClienteStatus] = useState(0);

  const [identificacionAvalStatus, setidentificacionAvalStatus] = useState(0);
  const [comprobanteAvalStatus, setcomprobanteAvalStatus] = useState(0);
  const [caratulaAvalStatus, setcaratulaAvalStatus] = useState(0);
  const [corporativaAvalStatus, setcorporativaAvalStatus] = useState(0);

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15
  };

  const optionsEstados = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },

  ]

  useEffect(() => {

    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      console.log("entro");
      AuthService.logout();

      navigate("/login", {
        state: { record: { correo: "" } },
      });


    }
    else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 50) / 100,
        });
      };

      InicializaFormulario();

      UpdateFlagtimeLineForm(true);
      UpdatebuttonIngresar("dashboard", usr.primerNombre + " " + usr.primerApellido);
      UpdateuserAvatar("dashboard");
      UpdatecurrentStep(6);
      UpdatemovilSteps(6);
      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener("resize", handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);

      };
    }


  }, []);

  const InicializaFormulario = async () => {

    ShowLoading(true);

    var respuesta = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      return false;
    }
    else {
      respuesta = await FormulariosService.getListaDocumentos(solicitudId);
    }

     UpdateintSolicitudId(solicitudId);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      }
      else {
        ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      }
      return false;
    }

    let respuestaCliente = await FormulariosService.getInformacionCliente(solicitudId);

    if (respuestaCliente.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      }
      else {
        ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      }
      return false;
    }

    if(respuestaCliente.data.clienteTipoRegimenFiscalId==1){
      setPmisVisible(false);
    }
    else{
      setPmisVisible(true);
    }

    //console.log(respuestaCliente);

    let respuestaAval = await FormulariosService.getDatosAval(solicitudId);

    if (respuestaAval.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      }
      else {
        ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      }
      return false;
    }

    if(respuestaCliente.data.avalTipoRegimenFiscalId==1){
      setPmisVisibleAval(false);
    }
    else{
      setPmisVisibleAval(true);
    }
    
    //console.log(respuestaAval);

    console.log(respuesta);

    respuesta.data.documentoList.map((documento, index) => {

      switch (documento.tipoDocumento) {
        case 1:
            setidentificacionClienteStatus(1);
          break;
        case 2:
            setcomprobanteClienteStatus(1);
            break;
        case 3:
            setcaratulaClienteStatus(1);
            break;
        case 4:
            setcorporativaClienteStatus(1);
            break;
        case 5:
            setidentificacionAvalStatus(1);
            break;
        case 6:
            setcomprobanteAvalStatus(1);
            break;
        case 8:
            setcaratulaAvalStatus(1);
            break;
          case 8:
            setcorporativaAvalStatus(1);
            break;
        default:
          break;
      }

    });
      
   

    ShowLoading(false);
  }

  const handleNext = (option) => {
    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };


  const onFinish = () => {
    handleNext("/datos_empresa");
  }

  const onChange = () => {

  }

  const handleBack = () => {
    handleNext("/datos_contacto");
  }

  const optionsDestino = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },

  ]

  const marks = {
    12: "12",
    24: "24",
    36: "36",
  };

  const marksMontos = {
    500000: "$500,000",
    800000: "$800,000",
    1000000: "$1000,000",
    1200000: "$1200,000",
    1500000: "$1500,000",
  };

  const showConfirm = (e) => {
    if (e.target.value == 1) {
      setPfaeisVisible(false);
      setPmisVisible(true);
    }
    else {
      setPfaeisVisible(true);
      setPmisVisible(false);
    }

    // if (statusRFC == "0") {
    //   confirm({
    //     title:
    //       "El inversionista ya esta registrado, esta segur@ de cambiar su régimen fiscal?",
    //     icon: <ExclamationCircleFilled />,
    //     onOk() {
    //       onChange(e);
    //     },
    //     onCancel() {
    //       var val = 1;
    //       if (e.target.value == 1) {
    //         val = 2;
    //       } else {
    //         val = 1;
    //       }
    //       form.setFieldsValue({
    //         inversionista: {
    //           codRegFiscal: val,
    //         },
    //       });
    //     },
    //   });
    // } else {
    //   onChange(e);
    // }
  };


  return (

    <div style={{ backgroundColor: "#fff", paddingBottom: 50, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", height: windowSize.height, minHeight: 450 }}>
      <center>
        <Divider>
          <div className="title-steps" style={{ margin: 0, color: "#4c4cbc", marginTop: 0 }}>
            Documentos
          </div>
        </Divider>


        <div className="subtitle-localizacion" style={{ color: "#4c4cbc", marginTop: 0, marginBottom: 10 }}>
          Suba los siguientes documentos para continuar
        </div>


        <div className="div-documents-steps" style={{
          overflow: "auto",
          width: "100%",
          maxHeight: windowSize.heightScroll,
          
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Skeleton loading={loading} active={true}>
            <br></br>
            <div className="div-subtitle-documentos-1">Cliente:</div>

            <center>
              <Row>
                <Col span={27}>
                  <UploadButtonDocuments key={1} status={identificacionClienteStatus} tipo={"1"} icono={(<FaAddressCard style={{ marginTop: 20, fontSize: 45, marginLeft: 20 }} />)} etiqueta={"Identificación oficial de representante legal"}></UploadButtonDocuments>
                </Col>
                <Col span={27}>
                  <UploadButtonDocuments key={2} tipo={"2"} status={comprobanteClienteStatus} icono={(<FaFileCircleCheck style={{ marginTop: 20, fontSize: 45, marginLeft: 20 }} />)} etiqueta={"Comprobante de domicilio de la empresa"}></UploadButtonDocuments>
                </Col>
                <Col span={27}>
                  <UploadButtonDocuments key={3} status={caratulaClienteStatus} tipo={"3"} icono={(<FaFileInvoiceDollar style={{ marginTop: 20, fontSize: 45, marginLeft: 20 }} />)} etiqueta={"Caratula de estado de cuenta para dispersión"}></UploadButtonDocuments>
                </Col>

                <div style={{
                display: PmisVisible ? "block" : "none"
              }}>
                  <Col span={27}>
                    <UploadButtonDocuments key={4} status={corporativaClienteStatus} tipo={"4"} icono={(<FaFileCircleCheck style={{ marginTop: 20, fontSize: 45, marginLeft: 20 }} />)} etiqueta={"Información Coorporativa"}></UploadButtonDocuments>
                  </Col>
                </div>
                
              </Row>
            </center>

            <div className="div-subtitle-documentos">Aval:</div>
            <center>
              <Row>
                <Col span={27}>
                  <UploadButtonDocuments key={5} status={identificacionAvalStatus} tipo={"5"} icono={(<FaAddressCard style={{ marginTop: 20, fontSize: 45, marginLeft: 20 }} />)} etiqueta={"Identificación oficial de Aval"}></UploadButtonDocuments>
                </Col>
                <Col span={27}>
                  <UploadButtonDocuments key={6} status={comprobanteAvalStatus} tipo={"6"} icono={(<FaFileCircleCheck style={{ marginTop: 20, fontSize: 45, marginLeft: 20 }} />)} etiqueta={"Comprobante de domicilio"}></UploadButtonDocuments>
                </Col>

                <div style={{
                display: PmisVisibleAval ? "block" : "none"
              }}>
                  <Col span={27}>
                    <UploadButtonDocuments key={7} status={corporativaAvalStatus} tipo={"7"} icono={(<FaFileCircleCheck style={{ marginTop: 20, fontSize: 45, marginLeft: 20 }} />)} etiqueta={"Información Coorporativa"}></UploadButtonDocuments>
                  </Col>
                </div>

              </Row>
            </center>

          </Skeleton>
        </div>

        <div className="div-buttons-documentos" style={{ marginTop: 0, paddingLeft: 20, marginBottom: -30 }}>
          <Row style={{ marginLeft: 15 }}>
            <Col span={12}>
              <Button
                className="buttons-back-forward"
                type="primary"
                onClick={() => handleNext("/datos_aval")}
                icon={<ArrowLeftOutlined />}
              >
                Atras
              </Button>
            </Col>
            <Col>
              <Button
                className="buttons-back-forward"
                type="primary"
                onClick={() => handleNext("/entrevistapld")}
                htmlType="submit"
              >
                Continuar <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      </center>


    </div>
  );
};

export default DocumentosCliente;
