import LocalData from '../persistencia/LocalData';
//import { ComponentesContext } from "../contextos/componentesContext";

const login = (email, password) => {
  //const URL = process.env.REACT_APP_URL_API;
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const json = { username: '', password: '' };
  //const { userImagenUrl, UpdatesetuserImagenUrl } = useContext(ComponentesContext);
  //const json = { username:'', password:'',jwt:'', dataEmp: {fullname: "VICTOR ROGELIO VILLANUEVA AYON", primerNombre: "VICTOR", apellidoMaterno: "VILLANUEVA"}  };
  json.email = email;
  json.password = password;
  //json.token_recatcha =token;
  //goHome(json);
  //return 200;
  //const resp = fetch(`${URL}api/auth/authenticate`, {
  const resp = fetch(`${URL}auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Referrer-Policy': 'unsafe-url'
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined' || typeof responseData.codigoError == 'undefined') {
        return { estado: 500, msg: 'Se generó un error al realizar el login.' };
      } else {
        if (responseData.result) {
          goHome(responseData.data);
          return { estado: 200, msg: responseData.msg };
        } else {
          if (responseData.codigoError == '00003') {
            return { estado: 300, msg: responseData.msg };
          }
          else {
            return { estado: 500, msg: responseData.msg };    
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
      return 500;
    });
  return resp;
};

const getValidaSesion = () => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}auth`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${AuthService.getCurrentToken()}`,
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return { result: false };
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { result: false };
    });
  return resp;
};

const postCrearUsuario = (email, password) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const json = { username: '', password: '' };
  //const { userImagenUrl, UpdatesetuserImagenUrl } = useContext(ComponentesContext);
  //const json = { username:'', password:'',jwt:'', dataEmp: {fullname: "VICTOR ROGELIO VILLANUEVA AYON", primerNombre: "VICTOR", apellidoMaterno: "VILLANUEVA"}  };

  json.email = email;
  json.password = password;

  const resp = fetch(`${URL}auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return { estado: 500 };
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const register = (username, email, password) => {
  return JSON.parse('');
};

const getCurrentUser = () => {
  try {
    return LocalData.getItem('user');
  } catch (e) {}
};

const getCurrentToken = () => {
  try {
    return LocalData.getItem('token_login');
  } catch (e) {}
};

const getIsAuthenticated = async () => {
  try {
    let usr = LocalData.getItem('user');

    if (usr === null || usr === undefined) {
      return false;
    } else {
      return true;
    }
  } catch (e) {}
};

const logout = () => {
  LocalData.clearAll();
};

async function goHome(user) {
  let obj = await user;
  //console.log("USUARIO",obj);
  LocalData.save('token_login', obj.token);
  //LocalData.save('token_expired',obj.expired);
  LocalData.save('user', obj.usuario);
  //UpdatesetuserImagenUrl(obj.dataEmp.imagen_usuario);

  return obj.dataEmp;
}

function checkStatus(response) {
  if (response.status === 200 || response.status === 204) {
    console.log('response', Promise.resolve(response));
    return Promise.resolve(response);
  } else {
    throw new Error(`HTTP ${response.status} - ${response.statusText}`);
  }
}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getCurrentToken,
  getIsAuthenticated,
  getValidaSesion,
};

export default AuthService;
