import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import '../../Welcome.css';
import './DatosAval.css';

import {
  Modal,
  Typography,
  Button,
  Select,
  Input,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  InputNumber,
  Slider,
  Radio,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MessageOutlined,
  MailOutlined,
} from '@ant-design/icons';

import InputCustom from '../../../componentes/InputCustom';
import SearchCustom from '../../../componentes/SearchCustom';
import RfcInput from '../../../componentes/InputRfc';
import { ComponentesContext } from '../../../contextos/componentesContext';

import FormulariosService from '../../../services/formularios.Service';
import SepomexService from '../../../services/sepomex.Service';
import AuthService from '../../../services/auth.service';

const DatosAval = () => {
  const {
    UpdateFooterText,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    ModalMessage,
    CloseSession,
    RfcTypes,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [DisableForm, setDisableForm] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 54) / 100,
  });

  const [isModalOpen, setisModalOpen] = useState(false);
  const [ModalContent, setModalContent] = useState(null);

  const [PfTooltip, setPfTooltip] = useState('');
  const [PmTooltip, setPmTooltip] = useState('');

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(false);
  const [PmisVisible, setPmisVisible] = useState(true);

  const [NombreCompletoAval, setNombreCompletoAval] = useState('');
  const [listaPaises, setlistaPaises] = useState('');

  const [OptionsNacionalidad, setOptionsNacionalidad] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Mexicana </div>,
    },
  ]);

  const [OptionsEstados, setOptionsEstados] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsEstadosNacimiento, setOptionsEstadosNacimiento] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsOcupacion, setOptionsOcupacion] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsMunicipio, setOptionsMunicipio] = useState([
    {
      value: 'culiacan',
      label: <div className="lblOptionCombo">Culiacan </div>,
    },
    {
      value: 'navolato',
      label: <div className="lblOptionCombo">Navolato </div>,
    },
  ]);

  const [OptionsColonia, setOptionsColonia] = useState([
    {
      value: 'centro',
      label: <div className="lblOptionCombo">Centro </div>,
    },
    {
      value: 'miguel aleman',
      label: <div className="lblOptionCombo">Miguel Aleman </div>,
    },
  ]);

  const [formPaisNacimiento, setformPaisNacimiento] = useState('');
  const [formEstadoNacimiento, setformEstadoNacimiento] = useState('');

  const [formOcupacion, setformOcupacion] = useState('');

  const [valueRFC, setvalueRFC] = useState('');
  const [formRazonSocial, setformRazonSocial] = useState('');
  const [formNacionalidad, setformNacionalidad] = useState('');
  const [formCorreo, setformCorreo] = useState('');
  const [formTelefonoOficina, setformTelefonoOficina] = useState('');
  const [formExtOficina, setformExtOficina] = useState('');
  const [formCelular, setformCelular] = useState('');

  const [formPrimerNombre, setformPrimerNombre] = useState('');
  const [formSegundoNombre, setformSegundoNombre] = useState('');
  const [formPrimerApellido, setformPrimerApellido] = useState('');
  const [formSegundoApellido, setformSegundoApellido] = useState('');

  const [formCp, setformCp] = useState('');
  const [formEstado, setformEstado] = useState('');
  const [formMunicipio, setformMunicipio] = useState('');
  const [formColonia, setformColonia] = useState('');
  const [formCalle, setformCalle] = useState('');
  const [formExt, setformExt] = useState('');
  const [formInt, setformInt] = useState('');
  const [formCurp, setformCurp] = useState('');

  const [disableSelectColonia, setenableSelectColonia] = useState(true);

  const [formRegimenFiscal, setformRegimenFiscal] = useState(3);

  const [avalCapturado, setavalCapturado] = useState(false);

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 200,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 54) / 100,
        });
      };

      UpdateFlagtimeLineForm(true);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(5);
      UpdatemovilSteps(5);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      InicializaFormulario();

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);

    var respuesta = {};
    var respuestaEstados = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuesta = await FormulariosService.getDatosAval(solicitudId);
      respuestaEstados = await SepomexService.getEstadosSepomex();
    }

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    //console.log(respuesta);

    setDisableForm(!respuesta.data.habilitado);

    const optionocupacionList = respuesta.data.ocupacionList.map((item) => ({
      value: item.id,
      label: <Tooltip overlayInnerStyle={{marginLeft:-100, width:500, marginTop:-50}}  placement="up" title={item.nombre}>
              <div className="lblOptionCombOcupacion">{item.nombre}</div>
             </Tooltip>,
      nombre: item.nombre,
    }));
    setOptionsOcupacion(optionocupacionList);

    const optionnacionalidadList = respuesta.data.nacionalidadList.map(
      (item) => ({
        value: item.id,
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      })
    );
    setOptionsNacionalidad(optionnacionalidadList);
    setlistaPaises(optionnacionalidadList);

    const optionestadoNacimientoList = respuestaEstados.data.map((item) => ({
      value: item.id, // Convierte el ID a una cadena
      label: <div className="lblOptionCombo">{item.nombre}</div>,
      nombre: item.nombre,
    }));
    setOptionsEstadosNacimiento(optionestadoNacimientoList);

    if (
      respuesta.data.avalPaisNacimientoId == 0 ||
      respuesta.data.avalPaisNacimientoId == null ||
      respuesta.data.avalPaisNacimientoId == 142
    ) {
      setOptionsEstadosNacimiento(optionestadoNacimientoList);
    } else {
      const filteredData = optionnacionalidadList.filter(
        (item) => item.value === respuesta.data.avalPaisNacimientoId
      );

      setOptionsEstadosNacimiento([
        {
          value: 999, // Convierte el ID a una cadena
          label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
          nombre: filteredData[0].nombre,
        },
      ]);
    }

    setformEstadoNacimiento(null);

    if (respuesta.data.avalEstado != null) {
      const optionEstadosSepomex = [
        {
          value: parseInt(respuesta.data.avalEstado.id),
          label: (
            <div className="lblOptionCombo">
              {respuesta.data.avalEstado.nombre}
            </div>
          ),
        },
      ];
      setOptionsEstados(optionEstadosSepomex);
      setformEstado(parseInt(respuesta.data.avalEstado.id));

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Estado: parseInt(respuesta.data.avalEstado.id),
          },
        });
      }, 0);
    }

    if (respuesta.data.avalMunicipio != null) {
      const optionMunicipiosSepomex = [
        {
          value: parseInt(respuesta.data.avalMunicipio.id),
          label: (
            <div className="lblOptionCombo">
              {respuesta.data.avalMunicipio.nombre}
            </div>
          ),
        },
      ];
      setOptionsMunicipio(optionMunicipiosSepomex);
      setformMunicipio(parseInt(respuesta.data.avalMunicipio.id));

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Municipio: parseInt(respuesta.data.avalMunicipio.id),
          },
        });
      }, 0);
    }

    if (respuesta.data.avalEstado != null) {
      const optionColoniasSepomex = respuesta.data.coloniaList.map((item) => ({
        value: parseInt(item.id), // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      }));

      setOptionsColonia(optionColoniasSepomex);
      setenableSelectColonia(false);

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Colonia: parseInt(respuesta.data.avalColoniaId),
          },
        });
      }, 0);

      setformColonia(parseInt(respuesta.data.avalColoniaId));
    }

    setvalueRFC(respuesta.data.avalRfc);
    setformRazonSocial(respuesta.data.avalRazonSocial);
    setformCorreo(respuesta.data.avalEmail);
    setformTelefonoOficina(respuesta.data.avalTelefonoOficina);
    setformExtOficina(respuesta.data.avalExtensionOficina);
    setformCelular(respuesta.data.avalCelular);

    setformPrimerNombre(respuesta.data.avalPrimerNombre);
    setformSegundoNombre(respuesta.data.avalSegundoNombre);
    setformPrimerApellido(respuesta.data.avalPrimerApellido);
    setformSegundoApellido(respuesta.data.avalSegundoApellido);

    setformCp(respuesta.data.avalCodigoPostal);

    // setformEstado(respuesta.data.email);
    // setformMunicipio(respuesta.data.email);
    // setformColonia(respuesta.data.email);
    setformCalle(respuesta.data.avalCalle);
    setformExt(respuesta.data.avalNumeroExterior);
    setformInt(respuesta.data.avalNumeroInerior);
    setformCurp(respuesta.data.avalCurp);

    if (respuesta.data.avalRfc != '') {
      setavalCapturado(true);
    }

    setformNacionalidad(
      respuesta.data.avalNacionalidadId == 0
        ? 142
        : respuesta.data.avalNacionalidadId
    );

    var intTipoRegimen = 0;

    if (respuesta.data.avalTipoRegimenFiscalId != 0) {
      if (respuesta.data.avalTipoRegimenFiscalId == 1) {
        setPfaeisVisible(false);
        setPmisVisible(true);
        setformRegimenFiscal(1);
        intTipoRegimen = 3;
      }
      else if (respuesta.data.avalTipoRegimenFiscalId == 3) {
        setPfaeisVisible(false);
        setPmisVisible(true);
        setformRegimenFiscal(3);
        intTipoRegimen = 1;
      }
      else {
        setPfaeisVisible(true);
        setPmisVisible(false);
        setformRegimenFiscal(2);
        intTipoRegimen = 2;
      }
    }

    //PAIS NACIMIENTO
    setformPaisNacimiento(
      respuesta.data.avalPaisNacimientoId == 0 ||
        respuesta.data.avalPaisNacimientoId == null
        ? 142
        : respuesta.data.avalPaisNacimientoId
    );

    setformEstadoNacimiento(
      respuesta.data.avalPaisNacimientoId == null
        ? null
        : respuesta.data.avalEstadoNacimientoId == 0
        ? null
        : respuesta.data.avalEstadoNacimientoId == null
        ? 999
        : respuesta.data.avalEstadoNacimientoId.toString()
    );

    setformOcupacion(
      respuesta.data.avalOcupacionId == 0
        ? 458
        : respuesta.data.avalOcupacionId
    );

    setTimeout(function () {
      form.setFieldsValue({
        datosAval: {
          RFC: respuesta.data.avalRfc,
          PrimerNombre: respuesta.data.avalPrimerNombre,
          SegundoNombre: respuesta.data.avalSegundoNombre,
          ApellidoPaterno: respuesta.data.avalPrimerApellido,
          ApellidoMaterno: respuesta.data.avalSegundoApellido,
          Nacionalidad:
            respuesta.data.avalNacionalidadId == 0
              ? 142
              : respuesta.data.avalNacionalidadId,
          Correo: respuesta.data.avalEmail,
          codRegFiscal:
            respuesta.data.avalTipoRegimenFiscalId == 0
              ? 1
              : intTipoRegimen,
          TelefonoOficina: respuesta.data.avalTelefonoOficina,
          ExtensionOficina: respuesta.data.avalExtensionOficina,
          Celular: respuesta.data.avalCelular,
          Curp: respuesta.data.avalCurp,
          CodigoPostal: respuesta.data.avalCodigoPostal,
          Calle: respuesta.data.avalCalle,
          Ext: respuesta.data.avalNumeroExterior,
          Int: respuesta.data.avalNumeroInerior,
          RazonSocial: respuesta.data.avalRazonSocial,
          PaisNacimiento: 142,
          PaisNacimiento:
            respuesta.data.avalPaisNacimientoId == 0 ||
            respuesta.data.avalPaisNacimientoId == null
              ? 142
              : respuesta.data.avalPaisNacimientoId,
          EstadoNacimiento:
            respuesta.data.avalPaisNacimientoId == null
              ? null
              : respuesta.data.avalEstadoNacimientoId == 0
              ? null
              : respuesta.data.avalEstadoNacimientoId == null
              ? 999
              : respuesta.data.avalEstadoNacimientoId.toString(),
          Ocupacion:
            respuesta.data.avalOcupacionId == 0
              ? 458
              : respuesta.data.avalOcupacionId,
        },
      });
    }, 0);

    if (!respuesta.data.habilitado == true) {
      setPmDisabled(true);
      setPfDisabled(true);
      setenableSelectColonia(true);
    }

    ShowLoading(false);
  };

  const handleNext = async (option) => {
    var regEXRFC =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/;

    var regEXCURP =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w(\w(\w(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?$/;

    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (valueRFC == '') {
      ModalMessage('Mensaje', 'El RFC no puede estar en blanco.');
      return false;
    }

    if (!regEXRFC.test(valueRFC)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El RFC no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (formRegimenFiscal == 1 || formRegimenFiscal == 3 ) {
      if (formPrimerNombre == '') {
        ModalMessage('Mensaje', 'El Primer Nombre no puede estar en blanco.');
        return false;
      }

      if (formPrimerApellido == '') {
        ModalMessage(
          'Mensaje',
          'El Apellido Paterno no puede estar en blanco.'
        );
        return false;
      }

      if (formNacionalidad == '') {
        ModalMessage('Mensaje', 'Debes seleccionar una nacionalidad.');
        return false;
      }

      if (formCorreo == '') {
        ModalMessage('Mensaje', 'El Correo del aval no puede estar en blanco.');
        return false;
      }

      if (!regEXCorreo.test(formCorreo)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El Correo del aval no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formTelefonoOficina != '') {
        if (formTelefonoOficina.length < 10) {
          ShowLoading(false);
          ModalMessage(
            'Mensaje',
            'El teléfono de oficina no tiene un formato valido.'
          );
          return false;
        }
      }

      if (formCelular == '') {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular del aval no puede estar en blanco.'
        );
        return false;
      }

      if (formCelular.length < 10) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular del aval no tiene un formato valido.'
        );
        return false;
      }

      if (formCurp == '') {
        ModalMessage('Mensaje', 'La CURP no puede estar en blanco.');
        return false;
      }

      if (formCurp.length < 18) {
        ModalMessage(
          'Mensaje',
          'La CURP no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      // if (formPaisNacimiento == null) {
      //   ModalMessage(
      //     'Mensaje',
      //     'El Pais de Nacimiento no puede estar en blanco.'
      //   );
      //   return false;
      // }

      //console.log('estado nacimiento', formEstadoNacimiento);

      // if (formEstadoNacimiento == null) {
      //   ModalMessage(
      //     'Mensaje',
      //     'El Estado de Nacimiento no puede estar en blanco.'
      //   );
      //   return false;
      // }

      if (formOcupacion == null) {
        ModalMessage('Mensaje', 'La Ocupación no puede estar en blanco.');
        return false;
      }

      /*
      if (!regEXCURP.test(formCurp)) {
        ShowLoading(false);
        ModalMessage("Mensaje", "La CURP no tiene un formato valido, por favor verifique.");
        return false;
      }
      */
    } else {
      if (formRazonSocial == '') {
        ModalMessage('Mensaje', 'La Razon Social no puede estar en blanco.');
        return false;
      }

      if (formNacionalidad == '') {
        ModalMessage('Mensaje', 'Debes seleccionar una Nacionalidad.');
        return false;
      }

      if (formCorreo == '') {
        ModalMessage('Mensaje', 'El Correo no puede estar en blanco.');
        return false;
      }

      if (!regEXCorreo.test(formCorreo)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El correo no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formTelefonoOficina != '') {
        if (formTelefonoOficina.length < 10) {
          ShowLoading(false);
          ModalMessage(
            'Mensaje',
            'El Teléfono de Oficina no tiene un formato valido.'
          );
          return false;
        }
      }

      // if (formPaisNacimiento == null) {
      //   ModalMessage(
      //     'Mensaje',
      //     'El Pais de Nacimiento no puede estar en blanco.'
      //   );
      //   return false;
      // }

      // if (formEstadoNacimiento == null) {
      //   ModalMessage(
      //     'Mensaje',
      //     'El Estado de Nacimiento no puede estar en blanco.'
      //   );
      //   return false;
      // }

      if (formCelular == '') {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular del aval no puede estar en blanco.'
        );
        return false;
      }

      if (formCelular.length < 10) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular del aval no tiene un formato valido.'
        );
        return false;
      }

      if (formPrimerNombre == '') {
        ModalMessage('Mensaje', 'El Primer Nombre no puede estar en blanco.');
        return false;
      }

      if (formPrimerApellido == '') {
        ModalMessage(
          'Mensaje',
          'El Apellido Paterno no puede estar en blanco.'
        );
        return false;
      }
    }

    if (formCp == '') {
      ModalMessage('Mensaje', 'El Código Postal no puede estar en blanco.');
      return false;
    }

    if (formCp.length < 5) {
      ShowLoading(false);
      ModalMessage('Mensaje', 'El Código Postal no tiene un formato valido.');
      return false;
    }

    if (formEstado == '') {
      ModalMessage('Mensaje', 'El Estado no puede estar en blanco.');
      return false;
    }

    if (formMunicipio == '') {
      ModalMessage('Mensaje', 'El Municipio no puede estar en blanco.');
      return false;
    }

    if (formColonia == '') {
      ModalMessage('Mensaje', 'La Colonia no puede estar en blanco.');
      return false;
    }

    if (formCalle == '') {
      ModalMessage('Mensaje', 'La Calle no puede estar en blanco.');
      return false;
    }

    if (formExt == '') {
      ModalMessage('Mensaje', 'El Número Exterior no puede estar en blanco.');
      return false;
    }

    //console.log("tipo regimen", formRegimenFiscal);

    const json = {
      solicitudId: solicitudId,
      tipoRegimenFiscalId: formRegimenFiscal,
      rfc: valueRFC,
      razonSocial: formRazonSocial,
      primerNombre: formPrimerNombre,
      segundoNombre: formSegundoNombre,
      primerApellido: formPrimerApellido,
      segundoApellido: formSegundoApellido,
      curp: formCurp,
      nacionalidadId: formNacionalidad,
      email: formCorreo,
      telefonoOficina: formTelefonoOficina,
      extensionOficina: formExtOficina,
      celular: formCelular,
      codigoPostal: formCp,
      coloniaId: formColonia,
      calle: formCalle,
      numeroExterior: formExt,
      numeroInterior: formInt,

      paisNacimientoId: formPaisNacimiento,
      estadoNacimientoId:
        parseInt(formEstadoNacimiento) == 999
          ? null
          : parseInt(formEstadoNacimiento),
      ocupacionId: formOcupacion,
    };

    setNombreCompletoAval(
      formPrimerNombre +
        ' ' +
        formSegundoNombre +
        ' ' +
        formPrimerApellido +
        ' ' +
        formSegundoApellido
    );

    //console.log(json);

    ShowLoading(true);

    if (DisableForm == false) {
      let respuesta = await FormulariosService.postGuardaDatosAval(json);

      if (respuesta.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }
    }

    ShowLoading(false);
    //console.log(json);

    if (avalCapturado == false) {
      setisModalOpen(true);
    } else {
      navigate(option, {
        state: { record: { solicitudId: solicitudId } },
      });
    }
  };

  const onFinish = () => {
    handleNext('/datos_empresa');
  };

  const onChange = () => {};

  const handleBack = () => {
    navigate('/datos_contacto', {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const optionsDestino = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },
  ];

  const showConfirm = (e) => {
    if (e.target.value == 1 ) {
      setPfaeisVisible(false);
      setPmisVisible(true);
      setformRegimenFiscal(3);
    }
    else if (e.target.value == 3) {
      setPfaeisVisible(false);
      setPmisVisible(true);
      setformRegimenFiscal(1);
    }
    else {
      setPfaeisVisible(true);
      setPmisVisible(false);
      setformRegimenFiscal(2);
    }
  };

  const getCpData = async (strCp) => {
    ShowLoading(true);
    let cpData = await SepomexService.getCatalogosCodigoPostal(strCp);

    if (cpData.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar la colonia.');
      }
      return false;
    }

    const optionEstadosSepomex = [
      {
        value: parseInt(cpData.data.estado.id),
        label: (
          <div className="lblOptionCombo">{cpData.data.estado.nombre}</div>
        ),
      },
    ];
    setOptionsEstados(optionEstadosSepomex);
    setformEstado(parseInt(cpData.data.estado.id));

    const optionMunicipiosSepomex = [
      {
        value: parseInt(cpData.data.municipio.id),
        label: (
          <div className="lblOptionCombo">{cpData.data.municipio.nombre}</div>
        ),
      },
    ];
    setOptionsMunicipio(optionMunicipiosSepomex);
    setformMunicipio(parseInt(cpData.data.municipio.id));

    setTimeout(function () {
      form.setFieldsValue({
        datosAval: {
          Estado: parseInt(cpData.data.estado.id),
          Municipio: parseInt(cpData.data.municipio.id),
        },
      });
    }, 0);

    const optionColoniasSepomex = cpData.data.coloniaList.map((item) => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: <div className="lblOptionCombo">{item.nombre}</div>,
      nombre: item.nombre,
    }));

    setOptionsColonia(optionColoniasSepomex);
    setenableSelectColonia(false);
    ShowLoading(false);
  };

  const onSearch = (value, event) => {
    event.preventDefault();
    getCpData(value);
  };

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const filterOption = (input, option) => {
    const normalizedInput = removeAccents(input).toLowerCase();
    const normalizedOption = removeAccents(option?.nombre).toLowerCase();

    return normalizedOption.includes(normalizedInput);
  };

  const handleModalClose = () => {
    setisModalOpen(false);

    navigate('/firma_buro', {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const handleChangePaisNacimiento = async (value) => {
    //console.log(value);
    setformPaisNacimiento(value);

    let respuestaEstados = await SepomexService.getEstadosSepomex();

    const filteredData = listaPaises.filter((item) => item.value === value);

    if (value == 142) {
      //console.log(respuestaEstados);

      const optionestadoNacimientoList = respuestaEstados.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      }));
      setOptionsEstadosNacimiento(optionestadoNacimientoList);
      setformEstadoNacimiento(null);

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            EstadoNacimiento: null,
          },
        });
      }, 0);
    } else {
      setOptionsEstadosNacimiento([
        {
          value: 999, // Convierte el ID a una cadena
          label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
          nombre: filteredData[0].nombre,
        },
      ]);

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            EstadoNacimiento: 999,
          },
        });
      }, 0);

      setformEstadoNacimiento(999);
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        paddingBottom: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height: windowSize.height,
        minHeight: 450,
      }}
    >
      <center>
        <Divider>
          <div
            className="title-steps"
            style={{ margin: 0, color: '#4c4cbc', marginTop: 0 }}
          >
            Información del Aval
          </div>
        </Divider>
      </center>
      <center>
        <Skeleton loading={loading} active={true}>
          <div
            style={{
              overflow: 'auto',
              width: '110%',
              maxHeight: windowSize.heightScroll,
            }}
          >
            <Form
              className="form-steps"
              form={form}
              style={{ marginTop: -5, display: 'inline-block' }}
              name="horizontal_login"
              layout="inline"
              onFinish={onFinish}
              disabled={DisableForm}
            >
              <Form.Item name={['datosAval', 'codRegFiscal']}>
                <Radio.Group
                  name="codRegFiscal"
                  style={{ marginLeft: 30, marginTop: 5 }}
                  onChange={(e) => {
                    showConfirm(e);
                  }}
                  value={1}
                >
                  <Tooltip placement="right" title={PmTooltip}>
                    <Radio value={2} disabled={PmDisabled}>
                      <div
                        className="radiobutton-steps"
                        style={{
                          margin: 0,
                          color: '#081b3e',
                          marginBottom: -10,
                        }}
                      >
                        PM - Persona Moral
                      </div>
                    </Radio>
                  </Tooltip>

                  <Tooltip placement="left" title={PfTooltip}>
                    <Radio value={1} disabled={PfDisabled}>
                      <div
                        className="radiobutton-steps"
                        style={{
                          margin: 0,
                          color: '#081b3e',
                          marginBottom: -10,
                        }}
                      >
                        PF -Persona Física
                      </div>
                    </Radio>
                  </Tooltip>

                  <Tooltip placement="left" title={PfTooltip}>
                    <Radio value={3} disabled={PfDisabled}>
                      <div
                        className="radiobutton-steps"
                        style={{
                          margin: 0,
                          color: '#081b3e',
                          marginBottom: -10,
                        }}
                      >
                        PFAE -Persona Física con Actividad Empresarial
                      </div>
                    </Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>

              <div
                style={{
                  display: PmisVisible ? 'block' : 'none',
                }}
              >
                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'RFC']}
                      label="RFC (Con Homoclave)"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        { required: true, message: 'Ingresa el RFC del Aval.' },
                      ]}
                    >
                      <center>
                        <RfcInput
                          className="input-steps"
                          value={valueRFC}
                          onChange={setvalueRFC}
                          tipo={RfcTypes.Fisica}
                          onInput={(e) =>
                            (e.target.value = e.target.value.toUpperCase())
                          }
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'PrimerNombre']}
                      label="Primer Nombre"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Primer Nombre del Aval.',
                        },
                      ]}
                    >
                      <InputCustom
                        value={formPrimerNombre}
                        onChange={setformPrimerNombre}
                        inputtype={InputTypes.Text}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'SegundoNombre']}
                      label="Segundo Nombre"
                    >
                      <center>
                        <InputCustom
                          value={formSegundoNombre}
                          onChange={setformSegundoNombre}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'ApellidoPaterno']}
                      label="Apellido Paterno"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Primer Apellido del Aval.',
                        },
                      ]}
                    >
                      <InputCustom
                        value={formPrimerApellido}
                        onChange={setformPrimerApellido}
                        inputtype={InputTypes.Text}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'ApellidoMaterno']}
                      label="Apellido Materno"
                    >
                      <center>
                        <InputCustom
                          value={formSegundoApellido}
                          onChange={setformSegundoApellido}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'Nacionalidad']}
                      label="Nacionalidad"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa la Nacionalidad del Aval.',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsNacionalidad}
                        onChange={setformNacionalidad}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'Correo']}
                      label="Correo"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el correo electrónico del Aval.',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido.',
                        },
                      ]}
                    >
                      <center>
                        <Input
                          value={formCorreo}
                          onChange={(e) => {
                            setformCorreo(e.target.value);
                          }}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'TelefonoOficina']}
                      label="Teléfono de Oficina"
                    >
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formTelefonoOficina}
                          onChange={setformTelefonoOficina}
                          maxLength={10}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'ExtensionOficina']}
                      label="Extensión de Oficina"
                    >
                      <center>
                        <InputCustom
                          value={formExtOficina}
                          onChange={setformExtOficina}
                          inputtype={InputTypes.Integer}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'Celular']}
                      label="Número de Celular"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Celular del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formCelular}
                          onChange={setformCelular}
                          maxLength={10}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['invdatosAvalersion', 'Curp']}
                      label="CURP"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa la CURP del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          value={formCurp}
                          onChange={setformCurp}
                          inputtype={InputTypes.Curp}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'PaisNacimiento']}
                      label="País de Nacimiento"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el País de Nacimiento del Aval.',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsNacionalidad}
                        onChange={(e) => handleChangePaisNacimiento(e)}
                        value={formPaisNacimiento}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'EstadoNacimiento']}
                      label="Estado de Nacimiento"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Estado de Nacimiento.',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsEstadosNacimiento}
                        onChange={setformEstadoNacimiento}
                        value={formEstadoNacimiento}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosClientes', 'Ocupacion']}
                      label={<label style={{textAlign:"left"}} className='select-steps-ocupacion'><label style={{color:"red", fontSize:12}}>*</label>&nbsp;Ocupación:</label>}
                      style={{ paddingLeft: 0 }}
                      className='select-steps-ocupacion'
                      colon={false}
                    >
                      <center>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Seleccione una opción"
                          options={OptionsOcupacion}
                          value={formOcupacion}
                          onChange={setformOcupacion}
                          filterOption={filterOption}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Typography.Title
                  level={4}
                  style={{ marginTop: 5, marginLeft: -20 }}
                >
                  Domicilio
                </Typography.Title>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'CodigoPostal']}
                      label="Código Postal"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Código Postal del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <SearchCustom
                          className="search-steps-cols-aval"
                          inputtype={InputTypes.Integer}
                          value={formCp}
                          maxLength={5}
                          placeholder="Ingrese un C.P."
                          onSearch={onSearch}
                          onChange={setformCp}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item name={['datosAval', 'Estado']} label="Estado">
                      <Select
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsEstados}
                        onChange={setformEstado}
                        value={formEstado}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'Municipio']}
                      label="Municipio/Delegación"
                    >
                      <Select
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsMunicipio}
                        onChange={setformMunicipio}
                        value={formMunicipio}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'Colonia']}
                      label="Colonia"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa la Colonia del Aval.',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsColonia}
                        onChange={setformColonia}
                        value={formColonia}
                        disabled={disableSelectColonia}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'Calle']}
                      label={
                        <div style={{ width: 40, textAlign: 'left' }}>
                          Calle{' '}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa la Calle del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          value={formCalle}
                          onChange={setformCalle}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'Ext']}
                      label="No. Ext."
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Número Exterior del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          value={formExt}
                          onChange={setformExt}
                          inputtype={InputTypes.Integer}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item name={['datosAval', 'Int']} label="No. Int.">
                      <center>
                        <InputCustom
                          value={formInt}
                          onChange={setformInt}
                          inputtype={InputTypes.Integer}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: PfaeisVisible ? 'block' : 'none',
                }}
              >
                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'RFC']}
                      label="RFC (Con Homoclave)"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        { required: true, message: 'Ingresa el RFC del Aval.' },
                      ]}
                    >
                      <center>
                        <RfcInput
                          className="input-steps"
                          value={valueRFC}
                          onChange={setvalueRFC}
                          tipo={RfcTypes.Moral}
                          onInput={(e) =>
                            (e.target.value = e.target.value.toUpperCase())
                          }
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'RazonSocial']}
                      label="Razón Social"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa la Razón Social del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          className="input-steps"
                          value={formRazonSocial}
                          onChange={setformRazonSocial}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'Nacionalidad']}
                      label="Nacionalidad"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa la Nacionalidad del Aval.',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsNacionalidad}
                        onChange={setformNacionalidad}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'Correo']}
                      label="Correo"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el correo electrónico del Aval',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido',
                        },
                      ]}
                    >
                      <center>
                        <Input
                          value={formCorreo}
                          onChange={(e) => {
                            setformCorreo(e.target.value);
                          }}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'TelefonoOficina']}
                      label="Teléfono de Oficina"
                    >
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formTelefonoOficina}
                          onChange={setformTelefonoOficina}
                          maxLength={10}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'ExtensionOficina']}
                      label="Extensión de Oficina"
                    >
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formExtOficina}
                          onChange={setformExtOficina}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'PaisNacimiento']}
                      label="País de Nacimiento"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el País de Nacimiento del Aval.',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsNacionalidad}
                        onChange={(e) => handleChangePaisNacimiento(e)}
                        value={formPaisNacimiento}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'EstadoNacimiento']}
                      label="Estado de Nacimiento"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsEstadosNacimiento}
                        onChange={setformEstadoNacimiento}
                        value={formEstadoNacimiento}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'Celular']}
                      label="Número de Celular"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa EL Número de Celular del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          className="input-steps"
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formCelular}
                          onChange={setformCelular}
                          maxLength={10}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Typography.Title
                  level={4}
                  style={{ marginTop: 5, marginLeft: -20 }}
                >
                  Representante Legal
                </Typography.Title>
                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'PrimerNombre']}
                      label="Primer Nombre"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Primer Nombre del Aval.',
                        },
                      ]}
                    >
                      <InputCustom
                        value={formPrimerNombre}
                        onChange={setformPrimerNombre}
                        inputtype={InputTypes.Text}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'SegundoNombre']}
                      label="Segundo Nombre"
                    >
                      <center>
                        <InputCustom
                          value={formSegundoNombre}
                          onChange={setformSegundoNombre}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'ApellidoPaterno']}
                      label="Apellido Paterno"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Primer Apellido del Aval.',
                        },
                      ]}
                    >
                      <InputCustom
                        value={formPrimerApellido}
                        onChange={setformPrimerApellido}
                        inputtype={InputTypes.Text}
                        key={1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'ApellidoMaterno']}
                      label="Apellido Materno"
                    >
                      <center>
                        <InputCustom
                          value={formSegundoApellido}
                          onChange={setformSegundoApellido}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Typography.Title
                  level={4}
                  style={{ marginTop: 5, marginLeft: -20 }}
                >
                  Domicilio
                </Typography.Title>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'CodigoPostal']}
                      label="Código Postal"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Código Postal del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <SearchCustom
                          className="search-steps-cols-aval"
                          inputtype={InputTypes.Integer}
                          value={formCp}
                          maxLength={5}
                          placeholder="Ingrese un C.P."
                          onSearch={onSearch}
                          onChange={setformCp}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item name={['datosAval', 'Estado']} label="Estado">
                      <Select
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsEstados}
                        onChange={setformEstado}
                        value={formEstado}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'Municipio']}
                      label="Municipio/Delegación"
                    >
                      <Select
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsMunicipio}
                        onChange={setformMunicipio}
                        value={formMunicipio}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'Colonia']}
                      label="Colonia"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa la Colonia del Aval.',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="select-steps-clientes"
                        placeholder="Seleccione una opción"
                        options={OptionsColonia}
                        onChange={setformColonia}
                        value={formColonia}
                        disabled={disableSelectColonia}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosAval', 'Calle']}
                      label={
                        <div style={{ width: 40, textAlign: 'left' }}>
                          Calle
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa la Calle del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          value={formCalle}
                          onChange={setformCalle}
                          inputtype={InputTypes.Text}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosAval', 'Ext']}
                      label="No. Ext."
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa el Número Exterior del Aval.',
                        },
                      ]}
                    >
                      <center>
                        <InputCustom
                          value={formExt}
                          onChange={setformExt}
                          inputtype={InputTypes.Integer}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item name={['datosAval', 'Int']} label="No. Int.">
                      <center>
                        <InputCustom
                          value={formInt}
                          onChange={setformInt}
                          inputtype={InputTypes.Integer}
                          key={1}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>

          <div
            className="div-buttons"
            style={{ marginTop: 20, paddingLeft: 3, marginBottom: -40 }}
          >
            <Row gutter={5} style={{ marginLeft: 0 }}>
              <Col span={12}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleBack()}
                  icon={<ArrowLeftOutlined />}
                >
                  Atrás
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleNext('/firma_buro')}
                  htmlType="submit"
                >
                  Continuar <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </center>

      <Modal
        title={
          <div>
            <MailOutlined /> &nbsp; &nbsp; &nbsp; Solicitud de Autorización de
            Buró Enviada
          </div>
        }
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={[
          <Button type="primary" onClick={handleModalClose}>
            Continuar
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          Se ha enviado un correo para solicitar la autorización de consulta de
          Buró a su aval:
        </div>

        <Typography.Title
          level={5}
          style={{
            margin: 20,
            color: '#081b3e',
            marginBottom: -10,
            textAlign: 'center',
            marginBottom: 5,
          }}
        >
          {NombreCompletoAval}
        </Typography.Title>

        <div style={{ textAlign: 'center' }}> {valueRFC} </div>

        <div style={{ textAlign: 'center' }}>{formCorreo}</div>

        <div style={{ textAlign: 'center', marginTop: 20 }}>
          Una vez recibamos la autorización del aval, podrá continuar con el
          proceso de su solicitud.
        </div>
      </Modal>
    </div>
  );
};

export default DatosAval;
