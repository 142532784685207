import { Input, Alert } from "antd";
import React, { useState, useContext } from "react";
import { ComponentesContext } from "../contextos/componentesContext";

export default function InputCustom(props) {
  const { InputTypes } = useContext(ComponentesContext);
  const { value, onChange } = props;
  const [AlertVisible, setAlertVisible] = useState(false);

  const handleChange = (e) => {
    var reg = "";
    switch (props.inputtype) {
      case InputTypes.Rfc:
        reg =
          /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/;
        break;
      case InputTypes.Text:
        reg = /^[a-zA-Z\u00C0-\u00FF0-9\s.]*$/;
        break;
      case InputTypes.Integer:
        reg = /^[0-9]*$/;
        break;
      case InputTypes.Decimal:
        reg = /^\d*\.?\d*$/;
        break;
      case InputTypes.Curp:
          reg = /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w(\w(\w(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?$/;
          break;  
      default:
        console.log("no existe la opción");
    }

    if (props.inputtype == InputTypes.Rfc) {
      if (reg.test(e.target.value) || e.target.value === "") {
        onChange(e.target.value);
        setAlertVisible(false);
      } else {
        if (e.target.value.length < 12) {
          setAlertVisible(true);
        }
      }

      if (e.target.value.length == 1) {
        if (!isNaN(e.target.value)) {
          onChange("");
        }
      }
    }

    if (props.inputtype == InputTypes.Curp) {
      if (reg.test(e.target.value) || e.target.value === "") {
        onChange(e.target.value);
        setAlertVisible(false);
      } else {
        if (e.target.value.length < 18) {
          setAlertVisible(true);
        }
      }

      if (e.target.value.length == 1) {
        if (!isNaN(e.target.value)) {
          onChange("");
        }
      }
    }

    if (props.inputtype == InputTypes.Text || props.inputtype == InputTypes.Integer || props.inputtype == InputTypes.Decimal) {
      if (reg.test(e.target.value) || e.target.value === "") {
        onChange(e.target.value);
        setAlertVisible(false);
      }
      else{
        if (e.target.value.length == 1) {
          onChange("");
        }
        setAlertVisible(true);
      }

      
    }
  };

  return (
    <>
      <Input
        {...props}
        onChange={handleChange}
      />
      <div style={{ display: AlertVisible ? "block" : "none" }}>
        <Alert
          message={<div style={{ textAlign: "left" }}>Carácter inválido</div>}
          type="error"
          showIcon
        />
      </div>
    </>
  );
}
