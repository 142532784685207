import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, Outlet, useLocation, Link } from 'react-router-dom';
import Logo from '../componentes/Logo';
import LogoTiny from '../componentes/Logo_tiny';
//import LogoBlanco from "../componentes/Logo_tiny_white";
import imagen1 from '../asset/images/doc.png';
import AuthService from '../services/auth.service';

import FormulariosService from '../services/formularios.Service';

import {
  FaBuilding,
  FaCity,
  FaHotel,
  FaMoneyCheckDollar,
  FaMoneyBill1,
  FaMoneyBillWave,
  FaSackDollar,
  FaDollarSign,
  FaUserPlus,
  FaCloud,
  FaComments,
  FaFileArrowUp,
  FaKey,
  FaFileContract,
  FaFlagCheckered,
  FaLocationDot,
  FaClipboardUser,
  FaIdCard,
  FaCircle,
} from 'react-icons/fa6';
import './Main.css';

import CryptoJS from 'crypto-js';

import {
  Card,
  Typography,
  ConfigProvider,
  Collapse,
  Steps,
  Avatar,
  Button,
  Row,
  Col,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  SearchOutlined,
  IdcardOutlined,
  FundProjectionScreenOutlined,
  BankOutlined,
  AuditOutlined,
  TeamOutlined,
} from '@ant-design/icons';

import { ComponentesContext } from '../contextos/componentesContext';

const { Meta } = Card;
const { Panel } = Collapse;

const Main = (children) => {
  const {
    ModalMessage,
    buttonIngresar,
    UserAvatar,
    UpdateuserAvatar,
    UpdatebuttonIngresar,
    currentStep,
    FlagtimeLineForm,
    movilSteps,
    FlagtimeLineFormMovil,
    FlagtimeLineFormProductos,
    CloseSession,
    FooterText,
    UpdateFooterText,
  } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [notificationsCounter, setnotificationsCounter] = useState(0);
  const { Title } = Typography;
  const [currentUrl, setCurrentUrl] = useState('');

  const location = useLocation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 140,
    heightImage: window.innerHeight - 200,
  });

  const [titleState0, settitleState0] = useState('');
  const [titleState1, settitleState1] = useState('');
  const [titleState2, settitleState2] = useState('');
  const [titleState3, settitleState3] = useState('');
  const [titleState4, settitleState4] = useState('');

  const handleWindowResize = () => {
    if (window.innerWidth > 1000) {
      settitleState0('');
      settitleState1('');
      settitleState2('');
      settitleState3('');
      settitleState4('');
    } else {
      settitleState0('');
      settitleState1('');
      settitleState2('');
      settitleState3('');
      settitleState4('');
    }
  };

  window.addEventListener('resize', handleWindowResize);

  useEffect(() => {
    const handleScroll = () => {
      //const currentScrollPos = window.pageYOffset;
      //setShowMenu(prevScrollPos > currentScrollPos || currentScrollPos === 0);
      //setPrevScrollPos(currentScrollPos);
    };

    const url = window.location.href;
    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    setCurrentUrl(lastPart);

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 140,
        heightImage: window.innerHeight - 200,
      });

      //console.log(windowSize.height);
    };

    //window.addEventListener("scroll", handleScroll);
    window.addEventListener('resize', handleResize);

    //console.log('Ruta actual cargar:', location.pathname);

    if (location.pathname == '/solicitudes_activas') {
      UpdateFooterText('');
    } else {
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
    }

    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const items = [
    {
      key: '1',
      label: 'This is panel header 1',
      children: 'prueba',
    },
    {
      key: '2',
      label: 'This is panel header 2',
      children: 'prueba',
    },
  ];

  const handleNext = (option) => {
    navigate(option);
  };

  const handleSendHome = async () => {
    let usr = AuthService.getCurrentUser();

    //console.log('Ruta actual:', location.pathname);

    if (location.pathname == '/dashboard') {
      window.location.href = 'https://www.finamo.mx/';
      return false;
    }

    if (usr === null || usr === undefined) {
      UpdateuserAvatar('');
      UpdatebuttonIngresar('');
      window.location.href = 'https://www.finamo.mx/';
    } else {
      let respuesta = await FormulariosService.getSolicitudes();

      //console.log('solicitudes', respuesta);
      if (respuesta.result != true) {
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }

      if (respuesta.data.length > 0) {
        handleNext('/solicitudes_activas');
      } else {
        handleNext('/dashboard');
      }
    }
  };

  const handleRegresaColorStep = (step) => {
    if (currentStep < step) {
      return '#d3dfe4';
    } else if (currentStep > step) {
      return '#04bb73';
    } else {
      return '#4c4cbc';
    }
  };

  const handleTamanoStep = (step) => {
    if (currentStep < step) {
      return 30;
    } else if (currentStep > step) {
      return 30;
    } else {
      return 30;
    }
  };

  const handleProductos = () => {
    handleNext('/dashboard');
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4c4cbc',
        },
      }}
    >
      <div className="App">
        {showMenu && (
          <div style={{ boxShadow: '-1px 14px 5px -6px rgba(96,96,96,0.75)' }}>
            <nav className="menu">
              <a onClick={() => handleSendHome()} style={{ height: 60 }}>
                <Logo />
                <LogoTiny />
              </a>

              <div
                style={{ display: FlagtimeLineFormMovil ? 'block' : 'none' }}
              >
                <div
                  className="div-steps-movil"
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  {movilSteps}
                </div>
              </div>

              <div
                className="div-steps"
                style={{ marginLeft: 30, marginRight: 30 }}
              >
                <ul
                  className="menu-list"
                  style={{
                    height: 37,
                    marginTop: 5,
                    display: FlagtimeLineForm ? 'block' : 'none',
                  }}
                >
                  <Steps
                    current={currentStep}
                    responsive={false}
                    items={[
                      {
                        title: titleState0,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(0),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(0)}
                            icon={<FaMoneyCheckDollar />}
                          />
                        ),
                      },
                      {
                        title: titleState1,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(1),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(1)}
                            icon={<TeamOutlined />}
                          />
                        ),
                      },

                      {
                        title: titleState2,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(2),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(2)}
                            icon={<FaBuilding />}
                          />
                        ),
                      },
                      {
                        title: titleState3,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(3),
                              marginTop: -10,
                            }}
                            size={handleTamanoStep(3)}
                            icon={<FaLocationDot />}
                          />
                        ),
                      },

                      {
                        title: titleState3,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(4),
                              marginTop: -10,
                            }}
                            size={handleTamanoStep(4)}
                            icon={<FaClipboardUser />}
                          />
                        ),
                      },

                      {
                        title: titleState3,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(5),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(5)}
                            icon={<FaIdCard />}
                          />
                        ),
                      },

                      /*
                      {
                        title: titleState4, icon: <Avatar
                          style={{ backgroundColor: handleRegresaColorStep(6), marginTop: -10 }}
                          size={handleTamanoStep(6)}
                          icon={<FaFileArrowUp />}
                        />
                      },*/

                      /*
                      {
                        title: titleState4, icon: <Avatar
                          style={{ backgroundColor: handleRegresaColorStep(7), marginTop: -5 }}
                          size={handleTamanoStep(7)}
                          icon={<FaComments />}
                        />
                      },
                      */
                      {
                        title: titleState4,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(9),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(9)}
                            icon={<FaCloud />}
                          />
                        ),
                      },

                      {
                        title: titleState4,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(10),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(10)}
                            icon={<FaFlagCheckered />}
                          />
                        ),
                      },
                    ]}
                  />
                </ul>
              </div>

              <div
                className="div-productos"
                style={{
                  display: FlagtimeLineFormProductos ? 'block' : 'none',
                }}
              >
                <Button type="primary" onClick={handleProductos}>
                  Más productos
                </Button>
              </div>

              <ul className="menu-list" style={{ height: 37 }}>
                <Title
                  level={5}
                  style={{ textAlign: 'left', marginTop: 0, color: '#4f4f4f' }}
                >
                  <div className="div-user-button">{buttonIngresar}</div>
                </Title>
                &nbsp;&nbsp;&nbsp;
                {UserAvatar}
              </ul>
            </nav>
          </div>
        )}
      </div>

      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: 10,
          height: windowSize.height,
          maxHeight: windowSize.height,
          minHeight: 400,
        }}
      >
        <Outlet />
      </div>
      <div
        className="div-main-foother"
        style={{ paddingTop: 5, height: 10, zIndex: -1 }}
      >
        <div style={{ display: FooterText == '' ? 'none' : 'block' }}>
          {' '}
          <center>
            {' '}
            <Link style={{ fontSize: 11, color: "#4c4cbc", textDecoration: "underline", marginRight: 10 }} to="/aviso_privacidad">Aviso de privacidad</Link>
            <label style={{color: "#4c4cbc"}}>|</label>
            <Link style={{fontSize:11, color: "#4c4cbc", textDecoration: "underline", marginLeft:10}} to="/terminos_condiciones">Términos y condiciones</Link>
          </center>
        </div>
        <center>
          <label style={{ zIndex: 0, fontSize:10}}> {FooterText}</label>
        </center>
      </div>
    </ConfigProvider>
  );
};

export default Main;
