import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./Welcome.css";
import { Carousel, Typography, Button, Collapse, Input } from "antd";
import { PlusOutlined, UserOutlined, LoginOutlined, CheckOutlined } from "@ant-design/icons";

import UsuariosService from "../services/usuarios.Service";



import { ComponentesContext } from "../contextos/componentesContext";


const MailCode = () => {
  const { ModalMessage, UpdatebuttonIngresar, UpdateuserAvatar, UpdateFlagtimeLineForm, ShowLoading, UpdateFooterText } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const location = useLocation();

  const numInputs = 6; // Cantidad de inputs deseados
  const inputRefs = useRef([]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 200,
    heightImage: window.innerHeight - 185,
  });

  const [Correo, SetCorreo] = useState(
    typeof location.state.record.correo != "undefined"
      ? location.state.record.correo != null
        ? location.state.record.correo
        : ""
      : ""
  );

  const [codigoVerificacion, setcodigoVerificacion] = useState("");

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15
  };

  const handleInputChange = (index, value) => {

    //console.log(value);
    setcodigoVerificacion(codigoVerificacion + value);

    if (value.length === 1 && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();


    }
  };

  const handleKeyPress = (event, index) => {

  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && event.target.value === '') {
      inputRefs.current[index - 1].focus();
      setcodigoVerificacion(codigoVerificacion.substring(0, codigoVerificacion.length - 2));
    }
  };

  useEffect(() => {

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 200,
        heightImage: window.innerHeight - 185,
      });
    };

    UpdatebuttonIngresar("create");
    UpdateuserAvatar("");
    UpdateFlagtimeLineForm(false);
    UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");

    window.addEventListener("resize", handleResize);
    //window.addEventListener("scroll", handleScroll);
    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);

    };
  }, []);

  const handleNext = (option) => {
    navigate(option);
  };

  const handleValidaCodigo = async () => {
    var strCodigo = "";

    ShowLoading(true);

    for (let index = 0; index < numInputs; index++) {
      strCodigo += inputRefs.current[index].input.value;
    }
    
    let ValidaCodigo = await UsuariosService.postValidarOtp({
      email: Correo,
      otp: strCodigo, UsuariosService
    });

    ShowLoading(false);
    if (ValidaCodigo.result) {
      ModalMessage("Mensaje", "El código se validó correctamente, ya puedes iniciar sesión con tus nuevas credenciales.");

      navigate("/login", {
        state: { record: { correo: Correo } },
      });

      // handleNext("/dashboard");
    }
    else {
      ModalMessage("Mensaje", ValidaCodigo.msg);
      return false;
    }

  }

  const handleReenviarOtp = async () => {
    ShowLoading(true);

    let ReenviarOtp = await UsuariosService.getReenviarOtp(Correo);

    ShowLoading(false);
    if (ReenviarOtp.result) {
      ModalMessage("Mensaje", "El Código se a reenviado correctamente, favor de revisar en el correo proporcionado e ingrese dicho codigo para validar su correo.");
    }

  }

  return (

    <div style={{ backgroundColor: "#fff", marginTop: 5 }}>
      <center>
        <br></br>
        <div style={{ border: "solid #7272cb 1px", borderRadius: 15, width: "47%", minWidth: 300 }}>
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              marginTop: 5,
              borderRadius: 15,
              color: "#7272cb"

            }}
            border={0}
            className="account-validation-main-table"
          >
            <tbody>
              <tr>
                <td colSpan={1} style={{ textAlign: "center", height: 120 }}>
                  <p>
                    <Title
                      level={2}
                      style={{ textAlign: "center", marginTop: 20, color: "#4c4cbc" }}
                    >
                      Validemos tu correo electrónico.
                    </Title>
                  </p>
                  <p>
                    Ingresa el código que te hemos enviado al correo:{" "}
                    <b>{Correo}</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    height: 120,
                    backgroundColor: "#f8f8fc",
                    borderRadius: 10,
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.35)",
                  }}

                >
                  <center>
                    <table style={{ marginTop: 20 }}>
                      <tbody>
                        <tr>
                          {Array.from({ length: numInputs }, (_, index) => (
                            <td key={index} style={{ width: 35 }}>
                              <Input
                                ref={el => (inputRefs.current[index] = el)}
                                maxLength={1}
                                style={{ backgroundColor: 'white', fontSize: 20 }}
                                onChange={e => handleInputChange(index, e.target.value)}
                                onKeyDown={e => {
                                  handleKeyPress(e, index);
                                  handleKeyDown(e, index);
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </center>

                  <p>Ingresa el código de 6 dígitos.</p>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "center", height: 80 }}>
                  <a onClick={() => handleReenviarOtp()}> Reenviar código </a>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }}>
                  <Button
                    type="primary"
                    size={"default"}
                    style={{
                      marginTop: 20,
                      width: "100%",
                      backgroundColor: "#4c4cbc",
                      height: 40,
                      boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.20)",
                    }}
                    onClick={() => handleValidaCodigo()}
                    icon={<CheckOutlined style={{ fontSize: 18 }} />}
                  >
                    Validar correo electrónico
                  </Button>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "center", height: 45, }}>
                  <a onClick={() => handleNext("/login")} stile> Ya tengo una cuenta </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br></br>
      </center>
    </div>
  );
};

export default MailCode;
