import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import "./Welcome.css";
import "./SolicitudContacto.css"
import { Checkbox, Typography, Button, Select, Input, Modal } from "antd";
import { PlusOutlined, UserOutlined, InfoCircleOutlined } from "@ant-design/icons";


import {
  FaCircleInfo,
} from 'react-icons/fa6';

import { ComponentesContext } from "../contextos/componentesContext";

import AuthService from "../services/auth.service";
import FormulariosService from "../services/formularios.Service";
import CatalogosSepomex from "../services/sepomex.Service"
import AvisoPrivacidad from '../componentes/AvisoPrivacidad';
import TerminosCondiciones from "../componentes/Condiciones";


const SolicitudContacto = () => {
  const { ModalMessage, UpdatebuttonIngresar, UpdateuserAvatar, UpdateFlagtimeLineForm, UpdateFooterText, UpdateFlagtimeLineFormProductos, ShowLoading, CloseSession } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;

  const [formUsuarioId, setformUsuarioId] = useState(0);
  const [formPrimerNombre, setformPrimerNombre] = useState("");
  const [formSeguntoNombre, setformSeguntoNombre] = useState("");
  const [formPrimerApellido, setformPrimerApellido] = useState("");
  const [formSegundoApellido, setformSegundoApellido] = useState("");
  const [formTelefono, setformTelefono] = useState("");
  const [formEstado, setformEstado] = useState(null);
  const [formMunicipio, setformMunicipio] = useState(null);
  const [formCorreo, setformCorreo] = useState("");
  const [formCheckBox, setformCheckBox] = useState(false);

  const [flagDisableMunicipios, setflagDisableMunicipios] = useState(true);

  const [isModalOpen, setisModalOpen] = useState(false);
  const [TextoModal, setTextoModal] = useState(null);
  

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 200,
    heightImage: window.innerHeight - 185,
  });


  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15
  };


  const [optionsEstados, setoptionsEstados] = useState([
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },

  ]);

  const [optionsMunicipios, setoptionsMunicipios] = useState([]);
  


  useEffect(() => {

    let usr =  AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      console.log("entro");
      AuthService.logout();
      
      navigate("/login", {
        state: { record: { correo: "" } },
      });
      
      
    }
    else{
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 200,
          heightImage: window.innerHeight - 185,
        });
      };

      UpdatebuttonIngresar("dashboard", usr.primerNombre+" "+usr.primerApellido );
      UpdateuserAvatar("dashboard");
      UpdateFlagtimeLineForm(false);
      UpdateFlagtimeLineFormProductos(true);
      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");
      
      InicializaFormulario();
  
      window.addEventListener("resize", handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
  
      };
    }

    
  }, []);

  const InicializaFormulario = async () => {

    ShowLoading(true);

    let usr = AuthService.getCurrentUser();
    let respuestaSepomex = await CatalogosSepomex.getEstadosSepomex();
    let respuesta = await FormulariosService.getSolicitudContacto();
    
    console.log(respuestaSepomex);
    console.log(respuesta);
    
    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();
      
      if(respuestaSesion.result==false){
        CloseSession();
      }
      else{
        ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    setformPrimerNombre(respuesta.data.primerNombre);
    setformSeguntoNombre(respuesta.data.segundoNombre);
    setformPrimerApellido(respuesta.data.primerApellido);
    setformSegundoApellido(respuesta.data.segundoApellido);
    setformCorreo(respuesta.data.email);
    setformUsuarioId(usr.id);
    
    
    const optionEstadosSepomex = respuesta.data.estadoList.map((item) => ({
      value: item.id, // Convierte el ID a una cadena
      label: <div className="lblOptionCombo">{item.nombre}</div>,
      nombre: item.nombre,
    }));
    setoptionsEstados(optionEstadosSepomex);
    //setformEstado("15");

    ShowLoading(false);
  }

  const handleNext = async(option) => {
    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (formPrimerNombre == '') {
      ModalMessage('Mensaje', 'El Primer Nombre no puede estar en blanco.');
      return false;
    }

    if (formPrimerApellido == '') {
      ModalMessage('Mensaje', 'El Primer Apellido no puede estar en blanco.');
      return false;
    }

    if (formCorreo == '') {
      ModalMessage('Mensaje', 'El Correo no puede estar en blanco.');
      return false;
    }

    if (!regEXCorreo.test(formCorreo)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El correo no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (formTelefono == '') {
      ModalMessage('Mensaje', 'El Celular no puede estar en blanco.');
      return false;
    }

    if (formTelefono.length < 10) {
      ModalMessage('Mensaje', 'El Celular no puede ser menor a 10 dígitos.');
      return false;
    }

    if (formEstado == null) {
      ModalMessage(
        'Mensaje',
        'El Estado no puede estar en blanco.'
      );
      return false;
    }

    if (formMunicipio == null) {
      ModalMessage(
        'Mensaje',
        'El Municipio no puede estar en blanco.'
      );
      return false;
    }

    if (formCheckBox == false) {
      ModalMessage(
        'Mensaje',
        'Para poder continuar debe aceptar nuestros términos y condiciones, así como nuestro aviso de privacidad.'
      );
      return false;
    }


    const json = {
      usuarioId: formUsuarioId,
      primerNombre: formPrimerNombre,
      segundoNombre: formSeguntoNombre,
      primerApellido: formPrimerApellido,
      segundoApellido: formSegundoApellido,
      email: formCorreo,
      telefono: formTelefono,
      estadoId: formEstado,
      municipioId: formMunicipio
    };

    console.log(json);
    ShowLoading(true);

    let respuesta = await FormulariosService.postSolicitudContacto(json);

    if (respuesta.result != true) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al guardar el formulario.');
      return false;
    }
    ShowLoading(false);
    ModalMessage('Mensaje', 'Su solicitud fue registrada correctamente, pronto nos pondremos en contacto con usted.');

    navigate(option);
  };

  const handleEstadoChange = async(val) => {
    let respuesta = await CatalogosSepomex.getMunicipios(val);
    
    console.log(respuesta);
    
    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();
      
      if(respuestaSesion.result==false){
        CloseSession();
      }
      else{
        ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      }
      return false;
    }

    const optionMunicipiosSepomex = respuesta.data.map((item) => ({
      value: item.id, // Convierte el ID a una cadena
      label: <div className="lblOptionCombo">{item.nombre}</div>,
      nombre: item.nombre,
    }));
    setoptionsMunicipios(optionMunicipiosSepomex);
    setformEstado(val.toString());
    setflagDisableMunicipios(false);

  }

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const filterOption = (input, option) => {
    const normalizedInput = removeAccents(input).toLowerCase();
    const normalizedOption = removeAccents(option?.nombre).toLowerCase();

    return normalizedOption.includes(normalizedInput);
  };

  const handleCheckChange = (val) => {
    setformCheckBox(val);
  };

  const handeOpenModal = (tipo) => {
    
    setTextoModal(<TerminosCondiciones />);

    if (tipo == "aviso") {
      setTextoModal(<AvisoPrivacidad />);
    }
    
    setisModalOpen(true);
  };

  const handleModalClose= () => {
    setisModalOpen(false);
  };
  

  return (
    <>
    <table width={"100%"} style={{ borderRadius: 15, backgroundColor: "#ffffff", borderWidth: 0, borderStyle: "none", marginTop: 5 }} border={0}>
      <tbody>
        <tr>
          <td style={{height:40}}></td>
        </tr>
        <tr>
          <td style={{ textAlign: "center" }}>
            <center>
              <br></br>
              <table
                cellPadding={0}
                cellSpacing={0}
                style={{
                  width: "50%",
                  borderRadius: 15,
                  marginLeft: 0,
                }}
                border={0}
              >
                <tbody>
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center", borderTop: "solid #7272cb 1px", borderRadius: "15px 15px 0 0", borderLeft: "solid #7272cb 1px", borderRight: "solid #7272cb 1px" }}>
                      <p>
                        <Title level={2} style={{ marginTop: -5, color: "#4c4cbc" }}>
                        Solicitud de forma sencilla
                        </Title>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", height: 45, borderLeft: "solid #7272cb 1px", paddingLeft:10 }}>
                      <Input value={formPrimerNombre} onChange={ (e)=> {setformPrimerNombre(e.target.value)} } className="input-solicitud" placeholder="Primer Nombre" />
                    </td>
                    <td width={10}>
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "left", borderRight: "solid #7272cb 1px", paddingRight:10 }}>
                      <Input value={formSeguntoNombre} onChange={ (e)=> {setformSeguntoNombre(e.target.value)} }  className="input-solicitud" placeholder="Segundo Nombre" />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", height: 45, borderLeft: "solid #7272cb 1px" }}>
                      <Input value={formPrimerApellido} onChange={ (e)=> {setformPrimerApellido(e.target.value)} }  className="input-solicitud" placeholder="Primer Apellido" />
                    </td>
                    <td width={10}>
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "left", borderRight: "solid #7272cb 1px" }}>
                      <Input value={formSegundoApellido} onChange={ (e)=> {setformSegundoApellido(e.target.value)} } className="input-solicitud" placeholder="Segundo Apellido" />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", height: 45, borderLeft: "solid #7272cb 1px" }}>
                      <Input value={formCorreo} onChange={ (e)=> {setformCorreo(e.target.value)} }  className="input-solicitud" placeholder="Correo Electrónico" />
                    </td>
                    <td width={10}>
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "left", borderRight: "solid #7272cb 1px" }}>
                      <Input maxLength={10} value={formTelefono} onChange={ (e)=> {setformTelefono(e.target.value)} } className="input-solicitud" placeholder="Teléfono/WhatsApp(Incluye lada)" />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "right", height: 45, borderLeft: "solid #7272cb 1px" }}>
                      <Select
                        showSearch
                        placeholder="¿En qué estado resides?"
                        optionFilterProp="children"
                        className="input-solicitud"
                        style={{ textAlign: "left" }}
                        value={formEstado}
                        filterOption={filterOption}
                        options={optionsEstados}
                        onChange={handleEstadoChange}
                      />
                    </td>
                    <td width={10}>
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "left", borderRight: "solid #7272cb 1px" }}>
                      <Select
                        
                        showSearch
                        optionFilterProp="children"
                        className="input-solicitud"
                        placeholder="¿A qué municipio perteneces?"
                        options={optionsMunicipios}
                        onChange={setformMunicipio}
                        value={formMunicipio}
                        disabled={flagDisableMunicipios}
                        filterOption={filterOption}


                       
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3} style={{ textAlign: "center", height: 45, borderLeft: "solid #7272cb 1px", borderRight: "solid #7272cb 1px", paddingLeft:10 }}>
                      <Checkbox onChange={ (e)=> (handleCheckChange(e.target.checked)) } >Al continuar, aceptas nuestros <a onClick={() => handeOpenModal("condiciones")} stile> términos y condiciones </a> , así como nuestro <a onClick={() => handeOpenModal("aviso")} stile> aviso de privacidad. </a></Checkbox>
                    </td>
                    
                  </tr>

                  <tr>
                    <td colSpan={3} style={{ textAlign: "center", height: 80, borderBottom: "solid #7272cb 1px", borderLeft: "solid #7272cb 1px", borderRight: "solid #7272cb 1px", borderRadius: "0px 0px 15px 15px" }}>
                      <Button
                        type="primary"
                        size={"default"}
                        style={{
                          backgroundColor: "#4c4cbc",
                          height: 40,
                          fontWeight: "bold",
                          boxShadow: "0px -1px 10px 0px rgba(0,0,0,0.20)",
                        }}
                        onClick={() => handleNext("/dashboard")}
                        className="account-welcome-btn-create"
                      >
                        <PlusOutlined style={{ fontSize: "18px" }} />{" Registrar "}

                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br></br><br></br><br></br>
            </center>
          </td>
        </tr>
      </tbody>
    </table>
    
    <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: '#081b3e', marginBottom: 10 }}
            >
              <InfoCircleOutlined style={{ color: '#4c4cbc' }} /> &nbsp;{' '}
              <label style={{ color: '#4c4cbc' }}>
                Información.
              </label>
            </Typography.Title>
            
          </div>
        }
        /*
        closable={false}
        maskClosable={false}
        */
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={[<div></div>]}
        width={windowSize.width - 200}
        className="modal-contacto"
      >
        {TextoModal}
      </Modal>
    </>
  

  );
};

export default SolicitudContacto;
