import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputCustom from '../../../componentes/InputCustom';
import SearchCustom from '../../../componentes/SearchCustom';

import { ComponentesContext } from '../../../contextos/componentesContext';
import '../../Welcome.css';
import './DatosEmpresas.css';
import {
  Typography,
  Button,
  Select,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  Tooltip,
} from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { FaCircleQuestion } from 'react-icons/fa6';

import AuthService from '../../../services/auth.service';
import SepomexService from '../../../services/sepomex.Service';
import FormulariosService from '../../../services/formularios.Service';
import DatePickerCustom from '../../../componentes/DatePickerCustom';
import moment from 'moment';
import dayjs from 'dayjs';

const DatosEmpresas = () => {
  const dateFormat = 'DD-MM-YYYY';
  const {
    UpdateFooterText,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    ModalMessage,
    CloseSession,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 60) / 100,
  });

  const [formFechaOperaciones, setformFechaOperaciones] = useState('');

  const [DisableForm, setDisableForm] = useState(false);
  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);

  const [OptionsSectorEconomico, setOptionsSectorEconomico] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsEstados, setOptionsEstados] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsActividadEconomica, setOptionsActividadEconomica] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsMunicipio, setOptionsMunicipio] = useState([
    {
      value: 'culiacan',
      label: <div className="lblOptionCombo">Culiacan </div>,
    },
    {
      value: 'navolato',
      label: <div className="lblOptionCombo">Navolato </div>,
    },
  ]);

  const [OptionsColonia, setOptionsColonia] = useState([
    {
      value: 'centro',
      label: <div className="lblOptionCombo">Centro </div>,
    },
    {
      value: 'miguel aleman',
      label: <div className="lblOptionCombo">Miguel Aleman </div>,
    },
  ]);

  const [formSector, setformSector] = useState('');
  const [formActividad, setformActividad] = useState('');
  const [formCp, setformCp] = useState('');
  const [formEstado, setformEstado] = useState('');
  const [formMunicipio, setformMunicipio] = useState('');
  const [formColonia, setformColonia] = useState('');
  const [formCalle, setformCalle] = useState('');
  const [formExt, setformExt] = useState('');
  const [formInt, setformInt] = useState('');

  

  const [disableSelectColonia, setenableSelectColonia] = useState(true);

  const [ActividadesEconomicasSinFiltrar, setActividadesEconomicasSinFiltrar] =
    useState(true);

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 60) / 100,
        });
      };

      InicializaFormulario();
      UpdateFlagtimeLineForm(true);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(2);
      UpdatemovilSteps(2);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);

    var respuesta = {};
    var respuestaEstados = {};

    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuesta = await FormulariosService.getInformacionEmpresa(solicitudId);
      //respuestaEstados = await SepomexService.getEstadosSepomex();
    }

    //console.log(respuesta);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    setDisableForm(!respuesta.data.habilitado);
    if (!respuesta.data.habilitado == true) {
      setenableSelectColonia(true);
    }

    const optionSectorEconomico = respuesta.data.sectorEconomicoList.map(
      (item) => ({
        value: item.id.toString(), // Convierte el ID a una cadena
        label: item.nombre,
      })
    );

    setOptionsSectorEconomico(optionSectorEconomico);

    //console.log('actividades', respuesta.data.actividadEconomicaList);

    const optionActividadEconomica = respuesta.data.actividadEconomicaList.map(
      (item) => ({
        value: item.id.toString(), // Convierte el ID a una cadena
        label: item.nombre,
        sectorEconomicoId: item.sectorEconomicoId,
      })
    );
    setOptionsActividadEconomica(optionActividadEconomica);

    setActividadesEconomicasSinFiltrar(optionActividadEconomica);

    setformSector(
      respuesta.data.empresaSectorEconomicoId == 0
        ? null
        : respuesta.data.empresaSectorEconomicoId.toString()
    );
    setformActividad(
      respuesta.data.empresaActividadEconomicaId == 0
        ? null
        : respuesta.data.empresaActividadEconomicaId.toString()
    );

    setformCp(respuesta.data.empresaCodigoPostal);

    //console.log('Respuesta ', respuesta);

    if (respuesta.data.empresaEstado != null) {
      const optionEstadosSepomex = [
        {
          value: parseInt(respuesta.data.empresaEstado.id),
          label: respuesta.data.empresaEstado.nombre,
        },
      ];
      setOptionsEstados(optionEstadosSepomex);
      setformEstado(parseInt(respuesta.data.empresaEstado.id));
    }

    if (respuesta.data.empresaMunicipio != null) {
      const optionMunicipiosSepomex = [
        {
          value: parseInt(respuesta.data.empresaMunicipio.id),
          label: respuesta.data.empresaMunicipio.nombre,
        },
      ];
      setOptionsMunicipio(optionMunicipiosSepomex);
      setformMunicipio(parseInt(respuesta.data.empresaMunicipio.id));
    }

    if (respuesta.data.coloniaList.length > 0) {
      const optionColoniasSepomex = respuesta.data.coloniaList.map((item) => ({
        value: parseInt(item.id), // Convierte el ID a una cadena
        label: item.nombre,
      }));
      setOptionsColonia(optionColoniasSepomex);
      setformColonia(respuesta.data.empresaColoniaId);
      setenableSelectColonia(false);
    }

    setformCalle(respuesta.data.empresaCalle);
    setformExt(respuesta.data.empresaNumeroExterior);
    setformInt(respuesta.data.empresaNumeroInterior);

    
    
    var fecha = null;
    if (respuesta.data.empresaFechaInicioOperaciones != '') {
      fecha = dayjs(
        respuesta.data.empresaFechaInicioOperaciones.substring(8, 10) +
          '/' +
          respuesta.data.empresaFechaInicioOperaciones.substring(5, 7) +
          '/' +
          respuesta.data.empresaFechaInicioOperaciones.substring(0, 4),
        dateFormat
      );
      setformFechaOperaciones(fecha);

      //console.log("dia", respuesta.data.empresaFechaInicioOperaciones.substring(8, 10));

      //console.log("mes antes", respuesta.data.empresaFechaInicioOperaciones.substring(5, 7));
      //console.log("anio antes", respuesta.data.empresaFechaInicioOperaciones.substring(0, 4));
      //console.log("fecha antes", respuesta.data.empresaFechaInicioOperaciones);
      //console.log("fecha",fecha);
    }

    

    setTimeout(function () {
      form.setFieldsValue({
        datosEmpresa: {
          calle: respuesta.data.empresaCalle,
        },
      });
    }, 0);

    if (!respuesta.data.habilitado == true) {
      setenableSelectColonia(true);
    }

    ShowLoading(false);
  };

  const handleNext = async (option) => {
    //console.log(formSector);

    if (formSector == null) {
      ModalMessage('Mensaje', 'El Sector Económico no puede estar en blanco.');
      return false;
    }

    if (formActividad == null) {
      ModalMessage(
        'Mensaje',
        'La Actividad Económica no puede estar en blanco.'
      );
      return false;
    }

    if (formFechaOperaciones == '') {
      ModalMessage(
        'Mensaje',
        'La fecha de inicio de operaciones no puede estar en blanco'
      );
      return false;
    }

    if (formCp == '') {
      ModalMessage('Mensaje', 'El Código Postal no puede estar en blanco.');
      return false;
    }

    if (formColonia == null) {
      ModalMessage('Mensaje', 'La Colonia no puede estar en blanco.');
      return false;
    }

    if (formCalle == '') {
      ModalMessage('Mensaje', 'La Calle no puede estar en blanco.');
      return false;
    }

    if (formExt == '') {
      ModalMessage('Mensaje', 'El Número exterior no puede estar en blanco.');
      return false;
    }

    const json = {
      solicitudId: solicitudId,
      sectorEconomicoId: parseInt(formSector),
      actividadEconomicaId: parseInt(formActividad),
      codigoPostal: formCp,
      coloniaId: formColonia,
      calle: formCalle,
      numeroExterior: formExt,
      numeroInterior: formInt,

      fechaInicioOperaciones: formFechaOperaciones == "" ? "1900-01-01" : moment(new Date(formFechaOperaciones)).format(
        'YYYY-MM-DD'
      ),

    };

    //console.log(json);

    ShowLoading(true);

    if (DisableForm == false) {
      let respuesta =
        await FormulariosService.postGuardaInformacionEmpresa(json);

      if (respuesta.result != true) {
        ShowLoading(false);
        ModalMessage('Error', 'Se produjo un error al guardar el formulario.');
        return false;
      }
    }

    ShowLoading(false);

    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });

    //navigate(option);
  };

  const onFinish = () => {};

  const onChange = () => {};

  const onChangeEstado = async (e) => {
    let respuestaMunicipios = await SepomexService.getMunicipios(e);
    //console.log('estado', respuestaMunicipios);

    const optionMunicipiosSepomex = respuestaMunicipios.data.map((item) => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: item.nombre,
    }));

    setOptionsMunicipio(optionMunicipiosSepomex);
  };

  const onChangeMunicipio = async (e) => {
    let respuestaMunicipios = await SepomexService.getMunicipios(e);
    //console.log('estado', respuestaMunicipios);

    const optionMunicipiosSepomex = respuestaMunicipios.data.map((item) => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: item.nombre,
    }));

    setOptionsMunicipio(optionMunicipiosSepomex);
  };

  const handleBack = () => {
    //handleNext("/datos_cliente");

    navigate('/datos_cliente', {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const optionsDestino = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },
  ];

  const showConfirm = (e) => {
    if (e.target.value == 1) {
      setPfaeisVisible(true);
      setPmisVisible(false);
    } else {
      setPfaeisVisible(false);
      setPmisVisible(true);
    }
  };

  const getCpData = async (strCp) => {
    ShowLoading(true);

    if (strCp == '') {
      ModalMessage('Error', 'El código postal no puede estar en blanco.');
      ShowLoading(false);
      return false;
    }

    let cpData = await SepomexService.getCatalogosCodigoPostal(strCp);

    //console.log('datos codigo postal', cpData);

    if (cpData.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar la colonia.');
      }
      return false;
    }

    const optionEstadosSepomex = [
      {
        value: parseInt(cpData.data.estado.id),
        label: cpData.data.estado.nombre,
      },
    ];
    setOptionsEstados(optionEstadosSepomex);
    setformEstado(parseInt(cpData.data.estado.id));

    const optionMunicipiosSepomex = [
      {
        value: parseInt(cpData.data.municipio.id),
        label: cpData.data.municipio.nombre,
      },
    ];
    setOptionsMunicipio(optionMunicipiosSepomex);
    setformMunicipio(parseInt(cpData.data.municipio.id));

    const optionColoniasSepomex = cpData.data.coloniaList.map((item) => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: item.nombre,
    }));

    setOptionsColonia(optionColoniasSepomex);
    setformColonia(null);
    setenableSelectColonia(false);

    ShowLoading(false);

    // if (cpData.estado == 500 || cpData.estado == 99) {
    //   form.setFieldsValue({
    //     renapo: {
    //       estado: "",
    //       municipio: "",
    //       ciudad: "",
    //     },
    //   });
    //   setOptionsColonia([]);
    //   showModal("Codigo postal no localizado.");
    // } else {
    //   form.setFieldsValue({
    //     renapo: {
    //       estado: cpData.data[0].estado,
    //       municipio: cpData.data[0].municipio,
    //       ciudad: cpData.data[0].ciudad,
    //     },
    //   });
    //   setOptionsColonia(cpData.data);
    // }
  };

  const onSearch = (value, event) => {
    event.preventDefault();
    getCpData(value);
  };

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const filterOption = (input, option) => {
    const normalizedInput = removeAccents(input).toLowerCase();
    const normalizedOption = removeAccents(option?.label).toLowerCase();

    return normalizedOption.includes(normalizedInput);
  };

  /*
  const LoadActividadEconomica = async(sectorEconomicoId) => {
    let cpData = await SepomexService.getCatalogosCodigoPostal(sectorEconomicoId);

    console.log("datos codigo postal",cpData);

    if (cpData.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();
      
      if(respuestaSesion.result==false){
        CloseSession();
      }
      else{
        ModalMessage("Error", "Se produjo un error al cargar la colonia.");
      }
      return false;
    }

    const optionEstadosSepomex= [{value: parseInt(cpData.data.estado.id), label: cpData.data.estado.nombre  }];
    setOptionsEstados(optionEstadosSepomex);
    setformEstado(parseInt(cpData.data.estado.id));
  }
  */

  const handleChangeSector = async (value) => {
    setformSector(value);
    //console.log(value);

    //console.log('actividades', ActividadesEconomicasSinFiltrar);

    let filteredData = ActividadesEconomicasSinFiltrar.filter(
      (item) => item.sectorEconomicoId === parseInt(value)
    );

    //console.log('filtrado', filteredData);

    setOptionsActividadEconomica(filteredData);
    setformActividad(null);
  };

  const handleInfo = () => {
    //console.log('entro');
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        paddingBottom: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height: windowSize.height,
        minHeight: 450,
      }}
    >
      <center>
        <Divider>
          <div
            className="title-steps"
            style={{ margin: 0, color: '#4c4cbc', marginTop: 0 }}
          >
            Información de la empresa
          </div>
        </Divider>
      </center>
      <center>
        <Skeleton loading={loading} active={true}>
          <div
            style={{
              overflow: 'auto',
              width: '110%',
              maxHeight: windowSize.heightScroll,
            }}
          >
            <Form
              className="form-steps-empresa"
              form={form}
              style={{ marginTop: -5, display: 'inline-block' }}
              name="horizontal_login"
              layout="inline"
              onFinish={onFinish}
              disabled={DisableForm}
            >
              <Typography.Title level={4} style={{ margin: 0, marginTop: 0 }}>
                Giro de la empresa
              </Typography.Title>

              <Row gutter={20}>
                <Col span={25} style={{ textAlign: 'left' }}>
                  <div className="select-steps">
                    {' '}
                    <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                    Sector Económico:
                  </div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={25} style={{ textAlign: 'left' }}>
                  <center>
                    <Select
                      className="select-steps"
                      placeholder="Seleccione una opción"
                      options={OptionsSectorEconomico}
                      value={formSector}
                      onChange={handleChangeSector}
                    />
                  </center>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={25} style={{ textAlign: 'left' }}>
                  <div className="select-steps">
                    <label style={{ color: 'red', fontSize: 12 }}>*</label>
                    Actividad Económica:{' '}
                    <Tooltip
                      placement="right"
                      title={
                        'Si la empresa tiene registrada más de una actividad económica seleccione la principal.'
                      }
                    >
                      <a
                        style={{ color: '#4c4cbc', fontSize: 15 }}
                        onClick={handleInfo}
                      >
                        <FaCircleQuestion />
                      </a>
                    </Tooltip>{' '}
                  </div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={25} style={{ textAlign: 'left' }}>
                  <center>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      className="select-steps"
                      placeholder="Seleccione una opción"
                      options={OptionsActividadEconomica}
                      value={formActividad}
                      onChange={setformActividad}
                      filterOption={filterOption}
                    />
                  </center>
                </Col>
              </Row>

              <Typography.Title level={4} style={{ margin: 0, marginTop: 20 }}>
                Operaciones
              </Typography.Title>

              <Row gutter={20}>
                <Col span={25} style={{ textAlign: 'left' }}>

                  <div className="select-steps">
                    <label style={{ color: 'red', fontSize: 12 }}>*</label>
                    Fecha de inicio de Operaciones:{' '}
                    <Tooltip
                      placement="right"
                      title={
                        'Fecha de registro de la actividad económica más antigüa.'
                      }
                    >
                      <a
                        style={{ color: '#4c4cbc', fontSize: 15 }}
                        onClick={handleInfo}
                      >
                        <FaCircleQuestion />
                      </a>
                    </Tooltip>{' '}
                    </div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={25} style={{ textAlign: 'left' }}>
                  <center>
                    <DatePickerCustom
                      className="select-steps"
                      format={dateFormat}
                      // ref={(dateSelect) => { this.dateSelect = dateSelect }}
                      // disabledDate={(current) => {
                      //   return moment().add(-1, 'days')  >= current;
                      //   }}
                      value={formFechaOperaciones}
                      onChange={setformFechaOperaciones}
                    />
                  </center>
                </Col>
              </Row>

              <Typography.Title level={4} style={{ margin: 0, marginTop: 20 }}>
                Domicilio Fiscal
              </Typography.Title>

              <Row gutter={20}>
                <Col span={11} style={{ textAlign: 'left' }}>
                  <div className="input-steps-cols">
                    <label style={{ color: 'red', fontSize: 12 }}>*</label> CP:
                  </div>
                </Col>
                <Col span={11} style={{ textAlign: 'left' }}>
                  <div className="input-steps-cols">Estado:</div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={11} style={{ textAlign: 'left' }}>
                  <center>
                    <SearchCustom
                      className="search-steps-cols"
                      inputtype={InputTypes.Integer}
                      value={formCp}
                      maxLength={5}
                      placeholder="Ingrese un C.P."
                      onSearch={onSearch}
                      onChange={setformCp}
                    />
                  </center>
                </Col>
                <Col span={11} style={{ textAlign: 'left' }}>
                  <center>
                    <Select
                      className="input-steps-cols"
                      placeholder=""
                      value={formEstado}
                      options={OptionsEstados}
                      onChange={setformEstado}
                      disabled
                    />
                  </center>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={11} style={{ textAlign: 'left' }}>
                  <div className="input-steps-cols">Municipio/Delegación:</div>
                </Col>
                <Col span={11} style={{ textAlign: 'left' }}>
                  <div className="input-steps-cols">
                    <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                    Colonia:
                  </div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={11} style={{ textAlign: 'left' }}>
                  <center>
                    <Select
                      className="input-steps-cols"
                      placeholder=""
                      value={formMunicipio}
                      options={OptionsMunicipio}
                      onChange={setformMunicipio}
                      disabled
                    />
                  </center>
                </Col>
                <Col span={11} style={{ textAlign: 'left' }}>
                  <center>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      className="input-steps-cols"
                      placeholder="Seleccione una opción"
                      options={OptionsColonia}
                      onChange={setformColonia}
                      disabled={disableSelectColonia}
                      value={formColonia}
                      filterOption={filterOption}
                    />
                  </center>
                </Col>
              </Row>

              <Row className="row-3-cols">
                <Col span={9}>
                  <Form.Item
                    name={['datosEmpresa', 'calle']}
                    label={
                      <>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        &nbsp;Calle
                      </>
                    }
                  >
                    <InputCustom
                      inputtype={InputTypes.Text}
                      key={1}
                      value={formCalle}
                      onChange={setformCalle}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} style={{ textAlign: 'left' }}>
                  <Form.Item
                    name={['datosEmpresa', 'ext']}
                    label={
                      <>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        &nbsp;No. Ext.
                      </>
                    }
                  >
                    <center>
                      <InputCustom
                        inputtype={InputTypes.Integer}
                        key={1}
                        value={formExt}
                        onChange={setformExt}
                      />
                    </center>
                  </Form.Item>
                </Col>

                <Col span={7} style={{ textAlign: 'left' }}>
                  <Form.Item name={['datosEmpresa', 'int']} label="No. Int.">
                    <center>
                      <InputCustom
                        inputtype={InputTypes.Integer}
                        key={1}
                        value={formInt}
                        onChange={setformInt}
                      />
                    </center>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>

          <div style={{ marginTop: 20, marginBottom: -40 }}>
            <Row gutter={25} style={{ marginLeft: -15 }}>
              <Col span={12}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleBack()}
                  icon={<ArrowLeftOutlined />}
                >
                  Atrás
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleNext('/domicilio_localizacion')}
                  htmlType="submit"
                >
                  Continuar <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </center>
    </div>
  );
};

export default DatosEmpresas;
