import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../pages/Welcome.css";
import "../pages/Logged/Forms/ConfigurarCredito.css";
import { Checkbox, Typography, Button, Select, Input, Form, Row, Col, Divider, Skeleton, InputNumber, Slider, Radio, Tooltip } from "antd";
import { PlusOutlined, UserOutlined, ArrowLeftOutlined, ArrowRightOutlined, SaveOutlined } from "@ant-design/icons";

import InputCustom from "./InputCustom";
import { ComponentesContext } from "../contextos/componentesContext";

import AuthService from "../services/auth.service";
import FormulariosService from "../services/formularios.Service";

export default function EntrevistaPld(props) {
  const { ModalMessage, UpdatebuttonIngresar, UpdateuserAvatar, loading, UpdatecurrentStep, InputTypes, UpdateFlagtimeLineForm, UpdatemovilSteps, ShowLoading, CloseSession, UpdatesetisModalOpenPLD } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [solicitudId, SetsolicitudId] = useState(props.solicitudId);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 50) / 100,
  });

  const [PfTooltip, setPfTooltip] = useState("");
  const [PmTooltip, setPmTooltip] = useState("");

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);


  const [InputNombrePersonaVisible, setInputNombrePersonaVisible] = useState(null);
  const [InputAvalNombrePersonaVisible, setInputAvalNombrePersonaVisible] = useState(null);
  const [InputTerceroNombrePersonaVisible, setInputTerceroNombrePersonaVisible] = useState(null);
  const [formSolicitantePoliticoExpuesto, setformSolicitantePoliticoExpuesto] = useState(null);
  const [formAvalPoliticoExpuesto, setformAvalPoliticoExpuesto] = useState(null);
  const [formSolicitanteDependencia, setformSolicitanteDependencia] = useState("");
  const [formSolicitanteFunciones, setformSolicitanteFunciones] = useState("");
  const [formSolicitantePuesto, setformSolicitantePuesto] = useState("");
  const [formSolicitantePeriodo, setformSolicitantePeriodo] = useState("");
  const [formAvalDependencia, setformAvalDependencia] = useState("");
  const [formAvalFunciones, setformAvalFunciones] = useState("");
  const [formAvalPuesto, setformAvalPuesto] = useState("");
  const [formAvalPeriodo, setformAvalPeriodo] = useState("");

  
  const [formValSolicitantePoliticoExpuesto, setformValSolicitantePoliticoExpuesto] = useState(0);
  const [formValAvalPoliticoExpuesto, setformValAvalPoliticoExpuesto] = useState(0);

  const [formValSolicitanteCuentaPropia, setformValSolicitanteCuentaPropia] = useState(0);
  const [formSolicitanteActuaCuentaPropia, setformSolicitanteActuaCuentaPropia] = useState("");

  const [formValAvalCuentaPropia, setformValAvalCuentaPropia] = useState(0);
  const [formAvalActuaCuentaPropia, setformAvalActuaCuentaPropia] = useState("");

  const [formValTercero, setformValTercero ]  = useState(0);
  const [formTerceroNombre, setformTerceroNombre] = useState("");
  

  useEffect(() => {

    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log("entro");
      AuthService.logout();

      navigate("/login", {
        state: { record: { correo: "" } },
      });


    }
    else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 50) / 100,
        });
      };

      InicializaFormulario(solicitudId);
      //UpdateFlagtimeLineForm(true);
      //UpdatebuttonIngresar("dashboard", usr.primerNombre + " " + usr.primerApellido);
      //UpdateuserAvatar("dashboard");
      //UpdatecurrentStep(6);
      //UpdatemovilSteps(6);

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener("resize", handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);

      };
    }


  }, [solicitudId]);


  const InicializaFormulario = async (id) => {

    ShowLoading(true);

    //console.log("inicializa", id);

    var respuesta = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      return false;
    }
    else {
      respuesta = await FormulariosService.getInformacionEntrevistaPld(solicitudId);
    }

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      }
      else {
        ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    //console.log("respuesta Aval", respuesta);

    setformValSolicitantePoliticoExpuesto(respuesta.data.solicitantePep);
    setformSolicitanteDependencia(respuesta.data.solicitanteDependencia);
    setformSolicitanteFunciones(respuesta.data.solicitantePrincipalesFunciones);
    setformSolicitantePuesto(respuesta.data.solicitantePuesto);
    setformSolicitantePeriodo(respuesta.data.solicitantePeriodoEjercicio);

    if (respuesta.data.solicitantePep== 1) {
      setformSolicitantePoliticoExpuesto(true);
    }
    else {
      setformSolicitantePoliticoExpuesto(false);
    }

    setformValAvalPoliticoExpuesto(respuesta.data.avalPep);
    setformAvalDependencia(respuesta.data.avalDependencia);
    setformAvalFunciones(respuesta.data.avalPrincipalesFunciones);
    setformAvalPuesto(respuesta.data.avalPuesto);
    setformAvalPeriodo(respuesta.data.avalPeriodoEjercicio);

    if (respuesta.data.avalPep == 1) {
      setformAvalPoliticoExpuesto(true);
    }
    else {
      setformAvalPoliticoExpuesto(false);
    }

    setformValSolicitanteCuentaPropia(respuesta.data.actuaCuentaPropiaCliente);
    setformSolicitanteActuaCuentaPropia(respuesta.data.nombrePersonaActuaCliente);


    if (respuesta.data.actuaCuentaPropiaCliente == null) {
      setInputNombrePersonaVisible(false);
    }
    else {
      if (respuesta.data.actuaCuentaPropiaCliente == 1) {
        setInputNombrePersonaVisible(false);
      }
      else {
        setInputNombrePersonaVisible(true);
      }
    }
    

    setformValAvalCuentaPropia(respuesta.data.actuaCuentaPropiaAval);
    setformAvalActuaCuentaPropia(respuesta.data.nombrePersonaActuaAval);

    if (respuesta.data.actuaCuentaPropiaAval == null) {
      setInputAvalNombrePersonaVisible(false);
    }
    else {
      if (respuesta.data.actuaCuentaPropiaAval == 1) {
        setInputAvalNombrePersonaVisible(false);
      }
      else {
        setInputAvalNombrePersonaVisible(true);
      }
    }
    

    setformValTercero(respuesta.data.terceroAportaRecursos);
    setformTerceroNombre(respuesta.data.terceroAportaRecursosNombre);

    if (respuesta.data.terceroAportaRecursos == 1) {
      setInputTerceroNombrePersonaVisible(true);
    }
    else {
      setInputTerceroNombrePersonaVisible(false);
    }

    //console.log("respuesta default",respuesta.data.actuaCuentaPropiaCliente);
    

    setTimeout(function () {
      form.setFieldsValue({
        entrevistapld: {
          solicitante_expuesto: respuesta.data.solicitantePep == 0 ? 2 : respuesta.data.solicitantePep,
          aval_expuesto: respuesta.data.avalPep == 0 ? 2 : respuesta.data.avalPep,
          cliente_propio: respuesta.data.actuaCuentaPropiaCliente == null ? 1 : respuesta.data.actuaCuentaPropiaCliente == 0 ? 2 : respuesta.data.actuaCuentaPropiaCliente,
          aval_propio: respuesta.data.actuaCuentaPropiaCliente == null ? 1 : respuesta.data.actuaCuentaPropiaAval == 0 ? 2 : respuesta.data.actuaCuentaPropiaAval,
          tercero: respuesta.data.terceroAportaRecursos == 0 ? 2 : respuesta.data.terceroAportaRecursos,
        },
      });
    }, 0);

    /*
    const optionnacionalidadList = respuesta.data.nacionalidadList.map(item => ({
      value: item.id, // Convierte el ID a una cadena
      label: (<div className="lblOptionCombo" >{item.nombre}</div>),
    }));
    setOptionsNacionalidad(optionnacionalidadList);

    if(respuesta.data.avalEstado!=null){
      const optionEstadosSepomex = [{ value: parseInt(respuesta.data.avalEstado.id), label: (<div className="lblOptionCombo" >{respuesta.data.avalEstado.nombre}</div>) }];
      setOptionsEstados(optionEstadosSepomex);
      setformEstado(parseInt(respuesta.data.avalEstado.id));

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Estado: parseInt(respuesta.data.avalEstado.id),
          },
        });
      }, 0);

    }

    if(respuesta.data.avalMunicipio!=null){
      const optionMunicipiosSepomex = [{ value: parseInt(respuesta.data.avalMunicipio.id), label: (<div className="lblOptionCombo" >{respuesta.data.avalMunicipio.nombre}</div>) }];
      setOptionsMunicipio(optionMunicipiosSepomex);
      setformMunicipio(parseInt(respuesta.data.avalMunicipio.id));

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Municipio: parseInt(respuesta.data.avalMunicipio.id),
          },
        });
      }, 0);
    }

    if(respuesta.data.avalEstado!=null){
      const optionColoniasSepomex = respuesta.data.coloniaList.map(item => ({
        value: parseInt(item.id), // Convierte el ID a una cadena
        label: (<div className="lblOptionCombo" >{item.nombre}</div>),
      }));
  
      setOptionsColonia(optionColoniasSepomex);
      setenableSelectColonia(false);

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Colonia: parseInt(respuesta.data.avalColoniaId),
          },
        });
      }, 0);

      setformColonia(parseInt(respuesta.data.avalColoniaId));
    }
    
    setvalueRFC(respuesta.data.avalRfc);
    setformRazonSocial(respuesta.data.avalRazonSocial);
    setformCorreo(respuesta.data.avalEmail);
    setformTelefonoOficina(respuesta.data.avalTelefonoOficina);
    setformExtOficina(respuesta.data.avalExtensionOficina);
    setformCelular(respuesta.data.avalCelular);

    setformPrimerNombre(respuesta.data.avalPrimerNombre);
    setformSegundoNombre(respuesta.data.avalSegundoNombre);
    setformPrimerApellido(respuesta.data.avalPrimerApellido);
    setformSegundoApellido(respuesta.data.avalSegundoApellido);

    setformCp(respuesta.data.avalCodigoPostal);

    // setformEstado(respuesta.data.email);
    // setformMunicipio(respuesta.data.email);
    // setformColonia(respuesta.data.email);
    setformCalle(respuesta.data.avalCalle);
    setformExt(respuesta.data.avalNumeroExterior);
    setformInt(respuesta.data.avalNumeroInerior);
    setformCurp(respuesta.data.avalCurp);


    setformNacionalidad(respuesta.data.avalNacionalidadId);

    if(respuesta.data.avalNacionalidadId!=0){
      if (respuesta.data.avalNacionalidadId == 2) {
        setPfaeisVisible(false);
        setPmisVisible(true);
        setformRegimenFiscal(1);
      }
      else {
        setPfaeisVisible(true);
        setPmisVisible(false);
        setformRegimenFiscal(2);
      }
    }

    

    
*/
    ShowLoading(false);
  }

  const handleBackForm = (option) => {
    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  }

  const handleNext = async (option) => {
    
    let data = form.getFieldsValue();

    //console.log("expuesto", data.entrevistapld);
    
    if (data.entrevistapld.solicitante_expuesto == null) {
      ModalMessage("Mensaje", "Debes seleccionar si el solicitante es una persona políticamente expuesta o no.");
      return false;
    }

    if (formSolicitantePoliticoExpuesto == true) {

      if (formSolicitanteDependencia == "") {
        ModalMessage("Mensaje", "La dependencia del solicitante no puede estar en blanco.");
        return false;
      }

      if (formSolicitanteFunciones == "") {
        ModalMessage("Mensaje", "Las funciones principales del solicitante no puede estar en blanco.");
        return false;
      }

      if (formSolicitantePuesto == "") {
        ModalMessage("Mensaje", "El puesto del solicitante no puede estar en blanco.");
        return false;
      }

      if (formSolicitantePeriodo == "") {
        ModalMessage("Mensaje", "El periodo de ejercicio del solicitante no puede estar en blanco.");
        return
        false;
      }

    }

    if (data.entrevistapld.aval_expuesto == null) {
      ModalMessage("Mensaje", "Debes seleccionar si el aval es una persona políticamente expuesta o no.");
      return false;
    }

    if (formAvalPoliticoExpuesto == true) {

      if (formAvalDependencia == "") {
        ModalMessage("Mensaje", "La dependencia del aval no puede estar en blanco.");
        return false;
      }

      if (formAvalFunciones == "") {
        ModalMessage("Mensaje", "Las funciones principales del aval no puede estar en blanco.");
        return false;
      }

      if (formAvalPuesto == "") {
        ModalMessage("Mensaje", "El puesto del aval no puede estar en blanco.");
        return false;
      }

      if (formAvalPeriodo == "") {
        ModalMessage("Mensaje", "El periodo de ejercicio del aval no puede estar en blanco.");
        return
        false;
      }
      
    }
    
    if (data.entrevistapld.cliente_propio == null) {
      ModalMessage("Mensaje", "Debes seleccionar si el cliente actúa en cuenta propia o no.");
      return false;
    }

    if (InputNombrePersonaVisible == true) {

      if (formSolicitanteActuaCuentaPropia == "") {
        ModalMessage("Mensaje", "El nombre del solicitante no puede estar en blanco.");
        return false;
      }

    }
    
    if (data.entrevistapld.aval_propio == null) {
      ModalMessage("Mensaje", "Debes seleccionar si el aval actúa en cuenta propia o no.");
      return false;
    }

    if (InputAvalNombrePersonaVisible == true) {

      if (formAvalActuaCuentaPropia == "") {
        ModalMessage("Mensaje", "El nombre de la persona por la que actúa el aval no puede estar en blanco.");
        return false;
      }

    }
    
    if (data.entrevistapld.tercero == null) {
      ModalMessage("Mensaje", "Debes seleccionar si hay un tercero que aporte recursos o no.");
      return false;
    }

    if (InputTerceroNombrePersonaVisible == true) {

      if (formTerceroNombre == "") {
        ModalMessage("Mensaje", "El nombre de la persona que actue como un tercero que aporte recursos regularmente no puede estar en blanco.");
        return false;
      }

    }

    //console.log("aval", formAvalPoliticoExpuesto);

    const json =
    {
      "solicitudId": solicitudId,
      "solicitantePep": formSolicitantePoliticoExpuesto == true ? 1 : 0,
      "solicitanteDependencia": formSolicitantePoliticoExpuesto == true ? formSolicitanteDependencia : "",
      "solicitantePrincipalesFunciones": formSolicitantePoliticoExpuesto == true ? formSolicitanteFunciones : "",
      "solicitantePuesto": formSolicitantePoliticoExpuesto == true ? formSolicitantePuesto : "",
      "solicitantePeriodoEjercicio": formSolicitantePoliticoExpuesto == true ? formSolicitantePeriodo : "",
      "avalPep": formAvalPoliticoExpuesto == true ? 1 : 0,
      "avalDependencia": formAvalPoliticoExpuesto == true ? formAvalDependencia : "",
      "avalPrincipalesFunciones": formAvalPoliticoExpuesto == true ? formAvalFunciones : "",
      "avalPuesto": formAvalPoliticoExpuesto == true ? formAvalPuesto : "",
      "avalPeriodoEjercicio": formAvalPoliticoExpuesto == true ? formAvalPeriodo : "",
      "actuaCuentaPropiaCliente": InputNombrePersonaVisible == false ? 1 : 0,
      "nombrePersonaActuaCliente": InputNombrePersonaVisible == true ? formSolicitanteActuaCuentaPropia : "",
      "actuaCuentaPropiaAval": InputAvalNombrePersonaVisible == false ? 1 : 0,
      "nombrePersonaActuaAval": InputAvalNombrePersonaVisible == true ? formAvalActuaCuentaPropia : "",
      "terceroAportaRecursos":  InputTerceroNombrePersonaVisible == true ? 1 : 0,
      "terceroAportaRecursosNombre": InputTerceroNombrePersonaVisible == true ? formTerceroNombre : "" 
    };

    ShowLoading(true);

    //console.log("json pld",json);

    
    let respuesta = await FormulariosService.postGuardaDatosPLD(json);
    //console.log("respuesta pld",json);

    //console.log(respuesta);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      }
      else {
        ModalMessage("Error", "Se produjo un error al cargar el formulario.");
      }
      return false;
    }

    //console.log(respuesta);
    
    ShowLoading(false);
    
    window.location.reload();
    
    UpdatesetisModalOpenPLD(false);
    
  };


  const onFinish = () => {
    handleNext("/datos_empresa");
  }

  const handleChangeOptionClientePropiaCuenta = (e) => {
    if (e.target.value == 1) {
      setInputNombrePersonaVisible(false);
    }
    else {
      setInputNombrePersonaVisible(true);
    }
  }

  const handleChangeOptionAvalPropiaCuenta = (e) => {
    if (e.target.value == 1) {
      setInputAvalNombrePersonaVisible(false);
    }
    else {
      setInputAvalNombrePersonaVisible(true);
    }
  }

  const handleChangeOptionSolicitantePoliticoExpuesto = (e) => {
    if (e.target.value == 1) {
      setformSolicitantePoliticoExpuesto(true);
    }
    else {
      setformSolicitantePoliticoExpuesto(false);
    }
  }

  const handleChangeOptionAvalPoliticoExpuesto = (e) => {
    if (e.target.value == 1) {
      setformAvalPoliticoExpuesto(true);
    }
    else {
      setformAvalPoliticoExpuesto(false);
    }

    //console.log(formAvalPoliticoExpuesto);
  }

  const handleChangeOptionTercerobeneficiario = (e) => {
    if (e.target.value == 1) {
      setInputTerceroNombrePersonaVisible(true);
    }
    else {
      setInputTerceroNombrePersonaVisible(false);
    }
  }

  return (
    <div style={{ backgroundColor: "#fff", paddingBottom: 50, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>

    <center>
      <Skeleton loading={loading} active={true}>

        <br></br>

        <div style={{ overflow: "auto", width: "110%", maxHeight: windowSize.heightScroll }}>
          <Form
            form={form}
            className="form-steps"
            style={{ marginTop: -5, display: "inline-block", marginLeft: -13 }}
            name="horizontal_login"
            layout="inline"
            onFinish={onFinish}
          >

            <div className="title-steps-pld" style={{ fontWeight: "bold", color:"#292929" }}>
              ¿El solicitante es una persona políticamente expuesta?
            </div>
            <Form.Item name={["entrevistapld", "solicitante_expuesto"]}>
              <Radio.Group
                name="codRegFiscal"
                onChange={(e) => {
                  handleChangeOptionSolicitantePoliticoExpuesto(e);
                }}
                value={1}
              >
                <Tooltip placement="left" title={PfTooltip}>
                  <Radio value={1} disabled={PfDisabled}>
                    <div className="radio-option">Sí</div>
                  </Radio>
                </Tooltip>

                <Tooltip placement="right" title={PmTooltip}>
                  <Radio value={2} disabled={PmDisabled}>
                    <div className="radio-option">No</div>
                  </Radio>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
            <div style={{ display: formSolicitantePoliticoExpuesto ? "block" : "none", marginTop: 10 }} >
              <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                Dependencia.
              </div>
              <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formSolicitanteDependencia} onChange={setformSolicitanteDependencia} style={{textAlign:"center"}} />

              <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                Principales Funciones.
              </div>
              <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formSolicitanteFunciones} onChange={setformSolicitanteFunciones} style={{textAlign:"center"}}/>

              <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                Puesto.
              </div>
              <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formSolicitantePuesto} onChange={setformSolicitantePuesto} style={{textAlign:"center"}}/>

              <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                Periodo de ejercicio.
              </div>
              <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formSolicitantePeriodo} onChange={setformSolicitantePeriodo} style={{textAlign:"center"}}/>
            </div>

            <br></br>

            <div className="title-steps-pld" style={{ fontWeight: "bold", color:"#292929" }}>
              ¿El aval es una persona políticamente expuesta?
            </div>

            <Form.Item name={["entrevistapld", "aval_expuesto"]}>
              <Radio.Group
                name="codRegFiscal"
                onChange={(e) => {
                  handleChangeOptionAvalPoliticoExpuesto(e);
                }}
                value={1}
              >
                <Tooltip placement="left" title={PfTooltip}>
                  <Radio value={1} disabled={PfDisabled}>
                    <div className="radio-option">Sí</div>
                  </Radio>
                </Tooltip>

                <Tooltip placement="right" title={PmTooltip}>
                  <Radio value={2} disabled={PmDisabled}>
                    <div className="radio-option">No</div>
                  </Radio>
                </Tooltip>
              </Radio.Group>
            </Form.Item>

            <div style={{ display: formAvalPoliticoExpuesto ? "block" : "none", marginTop: 10 }} >
              <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                Dependencia.
              </div>
              <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formAvalDependencia} onChange={setformAvalDependencia} style={{textAlign:"center"}}/>

              <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                Principales Funciones.
              </div>
              <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formAvalFunciones} onChange={setformAvalFunciones} style={{textAlign:"center"}}/>

              <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                Puesto.
              </div>
              <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formAvalPuesto} onChange={setformAvalPuesto} style={{textAlign:"center"}}/>

              <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                Periodo de ejercicio.
              </div>
              <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formAvalPeriodo} onChange={setformAvalPeriodo} style={{textAlign:"center"}}/>
            </div>

            <br></br>


            <div className="title-steps-pld" style={{ fontWeight: "bold", color:"#292929" }}>
              ¿El cliente actúa en cuenta propia?
            </div>

            <Form.Item name={["entrevistapld", "cliente_propio"]}>
              <Radio.Group
                name="codRegFiscal"
                onChange={(e) => {
                  handleChangeOptionClientePropiaCuenta(e);
                }}
                value={1}
              >
                <Tooltip placement="left" title={PfTooltip}>
                  <Radio value={1} disabled={PfDisabled}>
                    <div className="radio-option">Sí</div>
                  </Radio>
                </Tooltip>

                <Tooltip placement="right" title={PmTooltip}>
                  <Radio value={2} disabled={PmDisabled}>
                    <div className="radio-option">No</div>
                  </Radio>
                </Tooltip>
              </Radio.Group>
            </Form.Item>

            <br></br>
            <center>

              <div style={{
                display: InputNombrePersonaVisible ? "block" : "none",
              }}>
                <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                  Nombre de la persona por la que actúa.
                </div>


                <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formSolicitanteActuaCuentaPropia} onChange={setformSolicitanteActuaCuentaPropia} style={{textAlign:"center"}}/>
              </div>
            </center>

            <br></br>

            <div className="title-steps-pld" style={{ fontWeight: "bold", color:"#292929" }}>
              ¿El aval actúa en cuenta propia?
            </div>

            <Form.Item name={["entrevistapld", "aval_propio"]}>
              <Radio.Group
                name="codRegFiscal"
                onChange={(e) => {
                  handleChangeOptionAvalPropiaCuenta(e);
                }}
                value={1}
              >
                <Tooltip placement="left" title={PfTooltip}>
                  <Radio value={1} disabled={PfDisabled}>
                    <div className="radio-option">Sí</div>
                  </Radio>
                </Tooltip>

                <Tooltip placement="right" title={PmTooltip}>
                  <Radio value={2} disabled={PmDisabled}>
                    <div className="radio-option">No</div>
                  </Radio>
                </Tooltip>
              </Radio.Group>
            </Form.Item>

            <br></br>
            <center>

              <div style={{
                display: InputAvalNombrePersonaVisible ? "block" : "none",
              }}>
                <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                  Nombre de la persona por la que actúa el Aval.
                </div>


                <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formAvalActuaCuentaPropia} onChange={setformAvalActuaCuentaPropia} style={{textAlign:"center"}}/>
              </div>

              
            </center>

            <br></br>
            <div className="title-steps-pld" style={{ fontWeight: "bold", color:"#292929" }} >
              ¿Habrá un tercero que aporte recursos regularmente al contrato sin  ser el titular ni beneficiarse económicamente?
            </div>

            <Form.Item name={["entrevistapld", "tercero"]}>
              <Radio.Group
                name="codRegFiscal"
                onChange={(e) => {
                  handleChangeOptionTercerobeneficiario(e);
                }}
                value={1}
              >
                <Tooltip placement="left" title={PfTooltip}>
                  <Radio value={1} disabled={PfDisabled}>
                    <div className="radio-option">Sí</div>
                  </Radio>
                </Tooltip>

                <Tooltip placement="right" title={PmTooltip}>
                  <Radio value={2} disabled={PmDisabled}>
                    <div className="radio-option">No</div>
                  </Radio>
                </Tooltip>
              </Radio.Group>
            </Form.Item>

            <br></br>
            <center>
              <div style={{
                display: InputTerceroNombrePersonaVisible ? "block" : "none",
              }}>
                <div className="title-steps-pld" style={{fontWeight:"bold"}}>
                  Nombre del proveedor de recursos.
                </div>


                <InputCustom className="input-steps" inputtype={InputTypes.Text} key={1} value={formTerceroNombre} onChange={setformTerceroNombre} style={{textAlign:"center"}}/>
              </div>
            </center>

          </Form>


        </div>

        <div style={{ width: "100%", marginTop: 50, paddingLeft: 0, marginBottom: -30 }}>

        <Button
                className="buttons-back-forward"
                type="primary"
                onClick={() => handleNext("/firma_buro")}
                htmlType="submit"
              >
                <SaveOutlined /> Guardar 
        </Button>
        </div>
      </Skeleton>
    </center>
  </div>
  );
}
